import React, { useState, useEffect } from 'react'
import emailImage from '../../../assets/img/email.png'
import keyImage from '../../../assets/img/key.png'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { errorMessage } from '../../../constants/errorMessage'
import { Card, Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap'
import { Circles } from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { userSingIn } from '../../../redux/actions/singinAndSingUp'
import { useNavigate } from 'react-router'
import { getConfigDetails } from '../../../services/config'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/globalConstants'
import { getPartnerDetails, getPartnerLogo, isPermissionExist, sortPortalPermissionByKey } from '../../../utils/helper'
import { getPartnerDetailsBySLug } from '../../../redux/actions/userDetails'
import NoData from '../../NoData'
require('./login.scss')

function Login(props) {
  const { dispatch, loader, notify, isFetching, partnerDetails } = props
  const getAccessPermissionData = sortPortalPermissionByKey(partnerDetails, 'Access Permissions')
  const partner = window.location.toString().split('/')[3]
  const { register, handleSubmit, formState: { errors }, reset } = useForm()

  const [userEmail, setUserEmail] = useState('')

  const [passwordShown, setPasswordShown] = useState(false)
  const [cpasswordShown, setCPasswordShown] = useState(false)
  const [mpasswordShown, setMPasswordShown] = useState(false)
  const [isValidPartner, setIsValidPartner] = useState(false)
  // const [facilityData, setFacilityData] = useState(EMPTY_OBJECT)
  const navigate = useNavigate()

  useEffect(() => {
    // const facilityDetails = JSON.parse(localStorage.getItem('faclityData') ? localStorage.getItem('faclityData') : '{}')
    // setFacilityData(facilityDetails)
    const getEmailUer = JSON.parse(localStorage.getItem('userEmail'))
    reset(formValues => ({ ...formValues, 'username': getEmailUer }))
  }, [EMPTY_ARRAY])

  useEffect(() => {
    if (partner) {
      dispatch(getPartnerDetailsBySLug(partner)).then((res) => {
        if (res?.data?.email) {
          // setPartnerDetails(res?.data)
          setIsValidPartner(true)
          localStorage.removeItem('partnerData')
          localStorage.setItem('partnerData', JSON.stringify(res?.data))
          const partnerData = res?.data
          let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
          let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
          rgb = JSON.parse(rgb)

          if (color) {
            document.documentElement.style.setProperty('--bs-primary', color)
            document.documentElement.style.setProperty('base-color', color)
            document.documentElement.style.setProperty('primary', color)
            document.documentElement.style.setProperty('textcolor', color)
            document.documentElement.style.setProperty('--bs-btn-bg', color)
            document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
            document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
            document.documentElement.style.setProperty('--bs-list-group-active-color', color)
            document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
          }

        } else {
          setIsValidPartner(false)
          localStorage.removeItem('partnerData')
          console.log('error')
        }

      }).catch(() => {
        console.log('error')

      })
    }

  }, [EMPTY_ARRAY])
  const togglePasswordVisiblity = (e, key) => {
    if (key === 'password') { setPasswordShown(passwordShown ? false : true) }
    if (key === 'mpassword') { setMPasswordShown(mpasswordShown ? false : true) }
    if (key === 'cpassword') { setCPasswordShown(cpasswordShown ? false : true) }
  }



  const handleRemeberme = (e) => {
    const { checked } = e.target
    if (checked) { localStorage.setItem('userEmail', JSON.stringify(userEmail !== '' ? userEmail : '')) }
    else if (!checked) { localStorage.removeItem('userEmail') }
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    if (name === 'username') { setUserEmail(value) }
  }

  const onSignIn = (data) => {
    if (!loader) {
      if (data?.username !== '' && data?.password !== '') {
        const payloadKeys = { 'client_id': getConfigDetails().CLIENT_ID, 'client_secret': getConfigDetails().CLIENT_SECRET, 'grant_type': 'password' }
        const finalPayload = { ...payloadKeys, ...data }
        delete finalPayload?.rememberMe
        dispatch(userSingIn(finalPayload)).then((res) => {
          if (res?.status === 201 && res?.data !== null) {
            localStorage?.setItem('sign_in', JSON.stringify(res?.data))
            localStorage?.setItem('isLoggedin', true)

            notify('success', 'User Login Successfully')
            reset(formValues => ({ ...formValues, 'username': '', 'password': '' }))
            const loginHistory = localStorage.getItem('loginHistory')
            if(loginHistory.includes('/reset-password')) {
              navigate('/'+partner)
            } else {
              navigate(loginHistory)
            }
            // window.open(`${getConfigDetails()?.portalURL}/${res?.data?.access_token}/${res?.data?.refresh_token}/1`, '_self')
            // navigate(0)
          } else {
            localStorage?.setItem('isLoggedin', false)

            notify('error', res?.errors?.message)
          }
        }).catch(() => {
          localStorage?.setItem('isLoggedin', false)

          notify('error', 'Something wants wrong!')
        })
      }
    }

  }

  const handleCreateAccount = () => {
    // navigate(0)
    // if(facilityData && facilityData?.facility?.short_name){
    //     navigate(`/${partner}/${facilityData?.facility?.short_name}/signup`)
    // }else{
    navigate(`/${partner}/signup`)
    // }
  }
  const handleForgotPassword = () => {
    // if(facilityData && facilityData?.facility?.short_name){
    //   navigate(`/${partner}/${facilityData?.facility?.short_name}/send-email`)
    // }else{
    navigate(`/${partner}/send-email`)
    // }
    // navigate(0)
  }

  return (
    isFetching ? null : isValidPartner ? <div>
      <Container className='mt-5 mb-3 m-auto loginComp'>
        <img width='230'
          src={getPartnerDetails()?.brand_setting ? getPartnerDetails()?.brand_setting?.logourl : getPartnerDetails()?.partner_details?.brand_setting?.logourl ? getPartnerDetails()?.partner_details?.brand_setting?.logourl : getPartnerLogo('parkengage')}
          alt={partner ? partner : 'e-Receipt'} />
        <p className='textloginNotes'>{errorMessage?.loginNotes}</p>
      </Container>

      <Form onSubmit={handleSubmit(onSignIn)}>
        <Container>
          <Card>
            <InputGroup>
              <InputGroup.Text id="inputLoginImage iconWidth"><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
              <Form.Control
                type="email"
                name='username'
                aria-describedby="basic-addon1"
                className='form-control inputLogin'
                id='loginInput'
                maxLength='40'
                minLength='1'
                placeholder="Enter Email Address"
                {...register('username', {
                  required: { value: true, message: 'This field is required' },
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'invalid email address' },
                  onChange: (e) => handleChangeInput(e),
                })} />
            </InputGroup>
            <InputGroup className='inputBorderUpper myiconset'>
              <InputGroup.Text id="inputLoginImage iconWidth"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
              <Form.Control type={passwordShown ? 'text' : 'password'} name='password' className="form-control passwordInput inputLogin" id="loginInput" placeholder="Enter Password" aria-describedby="basic-addon1"  {...register('password', { required: true })} />
              <i className={passwordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
            </InputGroup>
          </Card>
          {(errors?.username?.type === 'required' && errors?.password?.type === 'required') && <span style={{ color: 'red', fontSize: '13px' }}>Both field are required</span>}
          {(errors?.username?.type !== 'required' && errors?.username?.type === 'pattern' && errors?.password?.type === 'required') && <span style={{ color: 'red', fontSize: '13px' }}>{errors?.username?.message}</span>}
          {(errors?.username?.type !== 'required' && errors?.username?.type === 'pattern' && errors?.password?.type !== 'required') && <span style={{ color: 'red', fontSize: '13px' }}>{errors?.username?.message}</span>}
          {(errors?.username?.type !== 'required' && errors?.username?.type !== 'pattern' && errors?.password?.type === 'required') && <span style={{ color: 'red', fontSize: '13px' }}>Password field is required</span>}
          {(errors?.username?.type === 'required' && errors?.password?.type !== 'required') && <span style={{ color: 'red', fontSize: '13px' }}>Email field is required</span>}
        </Container>

        <Container className='mt-4'>
          <Row>
            <Col>
              <div className="form-check">
                <input className="form-check-input" type="checkbox" name='rememberMe' id="checkboxRemember" {...register('rememberMe', {
                  onChange: (e) => handleRemeberme(e)
                })} />
                <label className="form-check-label" id='LoginlableCheckBox'>
                  Remember me
                </label>
              </div>
            </Col>
            <Col>
              <label className="form-check-label" id='loginForgotPassword' onClick={handleForgotPassword}>Forgot Password?</label>
            </Col>
          </Row>
        </Container>

        <Container className="mt-3">
          <button type="submit" name="submit" disabled={loader} className="btn w-100 my-4 text-white bg-primary">
            <div className='d-flex justify-content-center align-items-center'>
              <span>Login </span>  <span className='ms-2'>
                {(loader) ? <Circles
                  height="20"
                  width="20"
                  style={{ display: 'inline-block' }}
                  color="#ffffff"
                  ariaLabel="circles-loading"
                  visible={true}
                /> : null}
              </span>
            </div>
          </button>
        </Container>

        {(isPermissionExist(getAccessPermissionData, 'Receipt Signup Access')) && <Container className='loginComp'>
          <Row>
            <Col><hr className='dashMarkLoginStart' /></Col>
            <Col className='CircleContainerBadge'><div className="circle">Or</div></Col>
            <Col><hr className='dashMarkLoginEnd' /></Col>
          </Row>
        </Container>}

        {(isPermissionExist(getAccessPermissionData, 'Receipt Signup Access')) && <Container>
          <button type='button' className="btn border border-primary w-100 my-4 buttonOutlineStyle" onClick={handleCreateAccount}>Create Account</button>
        </Container>}
      </Form>
    </div> : <NoData message={errorMessage?.invalidPartner} />
  )
}


Login.propTypes = {
  dispatch: PropTypes.func,
  loader: PropTypes.bool,
  notify: PropTypes.func,
  isFetching: PropTypes.bool,
  partnerDetails: PropTypes.object
}

Login.defaulProps = {
  dispatch: noop,
  loader: false,
  notify: noop,
  isFetching: true,
  partnerDetails: EMPTY_OBJECT
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
  return {
    loader: singInAndSingUp?.loader,
    isFetching: userDetailsReducer?.isFetching,
  }
}
export default connect(mapStateToProps)(Login)