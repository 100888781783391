import { getConfigDetails } from '../services/config'

export const APICONFIG = {

	// pave ungated get faclity Data
	getFaclityUrl: `${getConfigDetails().baseURL}pave-web-get-facility-rate`,

	postUrlForPayment: `${getConfigDetails().baseURL}pave-make-web-ungated-checkin-payment`,

	// main-street 
	getFaciltyUrl: `${getConfigDetails().baseURL}get-facility`,
	// add card Details
	postCardDetails: `${getConfigDetails().baseURL}transient-make-payment`,
	// extend Page 
	getExtendDetails: `${getConfigDetails().baseURL}pave-extend-payment-details`,
	postExtendDays: `${getConfigDetails().baseURL}pave-days-amount`,
	postExtendSubmit: `${getConfigDetails().baseURL}pave-make-extend-payment`,

	ticketValidateUrl: `${getConfigDetails()?.baseURL}validate-qrbased-ticket`,
	getSearchUrl: `${getConfigDetails()?.baseURL}ticket-search`,
	getUserDetailUrl: `${getConfigDetails()?.baseURL}search-ticket-number`,
	sendSmsEmailUrl: `${getConfigDetails()?.baseURL}send-sms-email`,
	downloadTicketUrl: `${getConfigDetails()?.baseURL}download-ticket-details`,
	getPartnerDetails: `${getConfigDetails()?.baseURL}partners-key-by-secret/${getConfigDetails()?.CLIENT_SECRET}`,
	getSearchTicketUrl: `${getConfigDetails()?.baseURL}get-receipt-ticket-details`,
	downloadEticketUrl: `${getConfigDetails()?.baseURL}download-receipt-pdfticket`,

	// End Current Parking
	endParking: `${getConfigDetails()?.baseURL}pave-end-session`,


	singInUrl: `${getConfigDetails().baseURL}oauth/access_token`,
	singupUrl: `${getConfigDetails().baseURL}sign-up`,
	sendEmailResetPassword: `${getConfigDetails().baseURL}user/reset-password/email`,
	
	// Passowrd Reset
	resetPassword: `${getConfigDetails()?.baseURL}user/reset-password`,
	// STORE EVENT LOG
	storeEventLog: `${getConfigDetails()?.baseURL}addUserEvent`,

	// STORE EVENT LOG
	// GET EXTEND LIST
	getExtendList: `${getConfigDetails()?.baseURL}get-ticket-extend-list`,


	// HANDLE URL FOR THE PROMPT MOBILE NUMBER UPDATE
	updateMobileNumber: `${getConfigDetails()?.baseURL}update-phone-number-by-ticket`,

	// GET PARTNER DETAILS BY SLUG
	getPartnerBySlug: `${getConfigDetails().baseURL}partner-details-by-slug`,

}
