import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { useEffect, useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { Form, Image, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { emailValidation, getErrorMessage } from '../../utils/helper'
import MiniHeader from './MiniHeader'
import emailImage from '../../assets/img/email.png'
import { getConfigDetails } from '../../services/config'
// import { useNavigate } from 'react-router'
import { sendEmailResetPassword } from '../../redux/actions/singinAndSingUp'

require('./login/login.scss')

function SendEmailResetPassword(props) {
    const partner = window.location.toString().split('/')
    // const partner = window.location.toString().split('/')[3]
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const dispatch = useDispatch()
    // const navigate = useNavigate()

    const { notify } = props
    useEffect(() => {
        setIsLoading(false)
    }, [])

    const handleInputChange = (e) => {
        const { value } = e.target
        setEmail(value)
        setErrorMessage('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        partner.pop()
        if (email == '') {
            setErrorMessage('Please Enter Email Id')
            return
        } else if (!emailValidation(email)) {
            setErrorMessage('Please Enter Correct Email Id')
            return
        } else {
            setIsLoading(true)
            let formData = {
                email: email,
                client_secret: getConfigDetails().CLIENT_SECRET,
                base_url: partner.join('/'),

            }
            dispatch(sendEmailResetPassword(formData)).then((res) => {
                setIsLoading(false)
                if (res?.status == '201') {
                    notify('success', res?.data?.message)
                    // navigate(`/${partner}/login`)
                    // navigate(0)
                } else {
                    notify('error', getErrorMessage(res))
                }
            })
        }
    }

    return (
        <div>
            <MiniHeader isDisplayBack title={'Forgot Password'} />
            <div className="login-popup">
                <div className='login-rt m-3'>
                    <div className="text-center text-center mb-5 mt-5">
                        Enter the email address associated with your account below. and we will send you a link to reset your password.
                    </div>
                    <form>
                        <InputGroup className='imageStyle mb-5 border rounded'>
                            <InputGroup.Text><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
                            <Form.Control
                                type="email"
                                name='email'
                                aria-describedby="basic-addon1"
                                className='form-control input-field border-0'
                                id='email'
                                maxLength='40'
                                minLength='1'
                                placeholder="Enter Email Address"
                                value={email}
                                autoComplete='false'
                                onChange={handleInputChange}
                            />
                        </InputGroup>
                        <span className='text-danger'>{errorMessage}</span>
                        <div className="form-group mt-4" >
                            <button
                                // type="button"
                                className="btn bg-primary text-white w-100 d-flex justify-content-center"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            >
                                {'Send'} {isLoading ? <span className=' ms-2'>
                                    <Circles
                                        height="20"
                                        width="20"
                                        color="#ffffff"
                                        ariaLabel="circles-loading"
                                        visible={true}
                                    /></span> : null}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}
SendEmailResetPassword.propTypes = {
    dispatch: PropTypes.func,
    notify: PropTypes.func,
}

SendEmailResetPassword.defaulProps = {
    dispatch: noop,
    notify: noop,
}

export default SendEmailResetPassword