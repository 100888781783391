
import CONSTANTS from '../constants'
import { putRequest } from '../../services'
import { APICONFIG } from '../apiConfig'


export const updatePhoneNumber = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.PUT_MOBILE_NUMBER_REQUEST })
    return putRequest(APICONFIG.updateMobileNumber, payload).then((res) => {
        dispatch({ type: CONSTANTS.PUT_MOBILE_NUMBER_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.PUT_MOBILE_NUMBER_FAILURE })
        return err
    })
}
