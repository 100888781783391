import React, { useEffect, useState } from 'react'
import { errorMessage } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import Footer from './Footer'
import MiniHeader from './MiniHeader'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { getPartnerDetailsBySLug } from '../../redux/actions/userDetails'

function NotFound(props) {
    const {dispatch, isFetching} = props
    const [partnerdetails, setPartnerDetails] = useState(EMPTY_OBJECT)
    const partner = window.location.toString().split('/')[3]
    const facility = window.location.toString().split('/')[4]


    useEffect(() => {
        if(partner){
            let slug = partner
			if(facility && facility != 'ticket'){
				slug = `${slug}/${facility}`
			}
            dispatch(getPartnerDetailsBySLug(slug)).then((res) => {
                if (res?.data?.email) {
                    setPartnerDetails(res?.data)
                    localStorage.removeItem('partnerData')
                    localStorage.setItem('partnerData',JSON.stringify(res?.data))
                    const partnerData = res?.data
                    let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
                    let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
                    rgb = JSON.parse(rgb)
                    if(color){
                        document.documentElement.style.setProperty('--bs-primary',color)
                        document.documentElement.style.setProperty('base-color',color)
                        document.documentElement.style.setProperty('primary',color)
                        document.documentElement.style.setProperty('textcolor',color)
                        document.documentElement.style.setProperty('--bs-btn-bg',color)
                        document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
                        document.documentElement.style.setProperty('--bs-list-group-active-border-color',color)
                        document.documentElement.style.setProperty('--bs-list-group-active-color',color)
                        document.documentElement.style.setProperty('--bs-list-group-active-bg',color)
                    }
                    
                } else {
                    console.log('error')
                }
                
            }).catch(() => {
                console.log('error')
                
            })
        }
        
    },[EMPTY_ARRAY])
    return (
        isFetching ? null : <div className='main'>
            <MiniHeader title='e-Ticket' partnerdetails={partnerdetails}/>
                <h1 className='text-center text-danger d-flex justify-content-center align-items-center'>404<br/>{partnerdetails?.id ? errorMessage?.notFound : errorMessage?.invalidPartner}</h1>
            <Footer/>
        </div>
    )
}
NotFound.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    cards: PropTypes?.array,
    notify: PropTypes?.func,
    isUserLogin: PropTypes?.bool,
}

NotFound.defaulProps = {
    dispatch: noop,
    loader: true,
    isFetching: true,
    cards: EMPTY_ARRAY,
    notify: noop,
    isUserLogin: false,
}

function mapStateToProps({ gatedCheckinReducer, PaymentReducer, signInSignUpReducer, userDetailsReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        cards: PaymentReducer?.data?.data?.payments,
        isUserLogin: signInSignUpReducer?.isUserLogin,
        isFetching: userDetailsReducer?.loader,

    }
}
export default connect(mapStateToProps)(NotFound)
