export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})

// demo
// export const CLIENT_SECRET = 'f0veUWhP1PXAoVLGyG4oR86yC'

// main-street- parking
export const CLIENT_SECRET = '9f3Blizhmt8qhkw62Y16qTJXa'

// demo
// export const CLIENT_ID = 'diamond-parking358221'

// main-street-parking 
export const CLIENT_ID = 'vikrant-tyagi356560'

export const PARTNER_ID = '358221'

export const formId = 'ipg_form'

export const CURRENCY = {
	usd: 'USD',
	euro: 'EURO',
	inr: 'INR'
}

export const STATUS = {
	active: 'Active',
	inactive: 'Inactive'
}



export const EVENT_TYPES = {
	EVENT: 'event',
	BUNDLE_PASS: 'bundle-pass',
	PARTY_PASS: 'party-pass',
	SIMPLE: 'simple-pass'
}



export const EVENT_TYPES_VALUES = {
	EVENT: 'Single Event',
	BUNDLE_PASS: 'Full Season',
	PARTY_PASS: 'Half Season',
	SIMPLE: '6 Pack'
}


export const EVENT_TYPES_VALUES_LOGS = {
	EVENT: 'SINGLE EVENT',
	BUNDLE_PASS: 'FULL SEASON',
	PARTY_PASS: 'HALF SEASON',
	SIMPLE: '6 PACK'
}

export const EVENT_LOG_TITLE = {
	HOME: 'MAPCO - HOME PAGE LOADED',
	BOOK_NOW_SINGLE_EVENT: 'MAPCO - BOOK NOW - SINGLE EVENT',
	PAYMENT_LOADED: 'MAPCO - PAYMENT PAGE - LOADED',
	PAYMENT: 'MAPCO - PAYMENT PAGE - USER AND CARD DETAILS',
	DOWNLOAD_TICKET: 'MAPCO - DOWNLOAD TICKET - CLICKED',
	RE_DOWNLOAD_TICKET: 'MAPCO - RE-DOWNLOAD TICKET - CLICKED',
	FINAL_PAGE: 'MAPCO - RESPONSE PAGE - LOADED',
	SUCCESS: 'MAPCO - RESPONSE PAGE - PAYMENT SUCCESS',
	PROMO_PAY_SUCCESS: 'MAPCO - RESPONSE PAGE - 100% OFF WITHOUT PAYMENT BOOKING SUCCESS',
	FAILURE: 'MAPCO - RESPONSE PAGE - PAYMENT FAILURE',
	RESPONSE_SUCCESS: 'MAPCO - RESPONSE PAGE - RESPONSE SUCCESS',
	RESPONSE_FAILURE: 'MAPCO - RESPONSE PAGE - RESPONSE FAILURE'
}

export const DEFAULT_EVENT_RATE = '0.00'
export const DEFAULT_IMAGE = 'blank-image'



export const MONTH_NAMES = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]
export const DAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]
export const EXPIRY_MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
export const EXPIRY_YEARS = [
	{
		val: '22',
		label: '2022'
	},
	{
		val: '23',
		label: '2023'
	},
	{
		val: '24',
		label: '2024'
	},
	{
		val: '25',
		label: '2025'
	},
	{
		val: '26',
		label: '2026'
	},
	{
		val: '27',
		label: '2027'
	},
	{
		val: '28',
		label: '2028'
	},
	{
		val: '29',
		label: '2029'
	},
	{
		val: '30',
		label: '2030'
	},
	{
		val: '31',
		label: '2031'
	},
	{
		val: '32',
		label: '2032'
	},
	{
		val: '33',
		label: '2033'
	},
	{
		val: '34',
		label: '2034'
	},
	{
		val: '35',
		label: '2035'
	},
	{
		val: '36',
		label: '2036'
	},
	{
		val: '37',
		label: '2037'
	},
	{
		val: '38',
		label: '2038'
	},
	{
		val: '39',
		label: '2039'
	},
	{
		val: '40',
		label: '2040'
	},
	{
		val: '41',
		label: '2041'
	},
	{
		val: '42',
		label: '2042'
	},
	{
		val: '43',
		label: '2043'
	},
	{
		val: '44',
		label: '2044'
	},
	{
		val: '45',
		label: '2045'
	},
	{
		val: '46',
		label: '2046'
	},
	{
		val: '47',
		label: '2047'
	},
	{
		val: '48',
		label: '2048'
	},
	{
		val: '49',
		label: '2049'
	},
	{
		val: '50',
		label: '2050'
	}


]

export const MONTHNAME = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
]
export const MONTHNAMESMALL = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
]
export const WEEKDAYS = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
]

export const CARDTYPES =
{
	VS: 'VS',
	MC: 'MC'
}


export const signupPayload = {
	'firstname': '',
	'lastname': '',
	'email': '',
	'phone': '',
	'address1': '',
	'address2': '',
	'city': '',
	'state': '',
	'zipcode': '',
	'password': '',
	'cpassword': '',
}

export const vehicleArray = [{ 'license_plate': '', 'make_model': '' }]
export const vehicleTableHeader = ['License Plate', 'Make / Modal', 'Action']
export const bookingTableHeader = ['Ticket Number', 'License Plate ', 'Check-in Date & Time', 'Amount', 'Vehicle', 'Action']

export const validPassword = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*?-])[A-Za-z0-9!@#$%^&*?-]{8,15}$')

export const userProfilePayload = {
	'email': '',
	'phone': '',
	'license_number': '',
	'address': '',
	'address2': '',
	'city': '',
	'state': '',
	'zipcode': ''
}
export const validEmail = new RegExp('/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+.)+[A-Za-z]+$/')
export const validLicenceNumber = new RegExp('^(?=.*[0-9]).{7,13}$')

export const dayData = []
for (let i = 0; i < 32; i++) {
	dayData.push({ key: i.toString(), no_of_days: (i === 1 || i === 0) ? `${i} Day` : `${i} Days`, val: i })
}

export const hoursData = []
for (let i = 1; i < 25; i++) {
	hoursData.push({ key: i.toString(), no_of_hours: i === 1 ? `${i} Hour` : `${i} Hours`, val: i })
}

export const minutesData = []
for (let i = 0; i < 61; i++) {
	minutesData.push({ key: i.toString(), no_of_minutes: i === 1 ? `${i} Minute` : `${i} Minutes`, val: i })
}


export const convertHoursToDaysMinutes = (a) => {
	let secs = a * 3600  // get total amount of seconds from your input
	const days = Math.floor(secs / (3600 * 24))
	secs -= days * 3600 * 24
	const hours = Math.floor(secs / 3600)
	secs -= hours * 3600
	const mins = Math.floor(secs / 60)
	secs -= mins * 60
	return { days: days.toString().padStart(2, '0'), hours: hours.toString().padStart(2, '0'), mins: mins.toString().padStart(2, '0'), secs: secs.toString().padStart(2, '0') }
}

export const validPhone = new RegExp('^[0-9]{10}$')