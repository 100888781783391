import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
// import visa from '../../assets/img/payment_icon/visa.png'
import { getCardIcon, getFormattedTime } from '../../utils/helper'

function ExtendDetails(props) {
    const { className, extendedDetails, paymentData} = props
    // const [discountedTotal, setDiscountedTotal] = useState(0)
    
    useEffect(() => {
        
    },[EMPTY_ARRAY])


    // const getFormattedTime = (totalhours, isConvert) => {

    //     const splittedTime = totalhours?.split('.')
    //     let minutes
    //     if(isConvert){
    //         minutes = (splittedTime?.[1]/100)*60
    //     }else{
    //         minutes = splittedTime?.[1]
    //     }
    //     const hours = splittedTime ? `${splittedTime?.[0] == 0 ? '00' : splittedTime?.[0] == 0 ? '00' : Number(splittedTime?.[0]) < 10 ? `0${splittedTime?.[0]} `: splittedTime?.[0]} : ${minutes != 0 ? minutes : '00'}` : ''
    //     return hours
    // }

    

   
    return (
        <div>
            
            {extendedDetails?.length > 0 && <div className={`${className}`}>
                    <table className='table table-responsive small mt-2'>
                        {extendedDetails?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <span className="text-primary">{index == 0 && paymentData?.reservation?.thirdparty_integration_id === '1' ? 'Started(Paid at Aggregator)' : index == 0 ? 'Started' : paymentData?.is_overstay == '1' ? 'Overstay' : paymentData?.is_extended == '1' ?'Extended':'Started'}</span>
                                        <small className="d-block text-muted"><i className="far fa-clock"></i> 
                                        {` ${getFormattedTime(item?.length, false)} ${Number(item?.length) > 1 ? 'HRS' : Number(item?.length) == 1 ? 'HR' :'MM'}`}
                                        
                                        {/* { index=='0'? diff(item?.checkin_time, item?.checkout_time): (item?.checkin_time && item?.checkout_time) ? diff(item?.checkin_time, item?.checkout_time) : diff(item?.check_in_datetime, item?.checkout_datetime)} */}
                                        {/* {`${getFormattedTime(item?.length, false)} ${Number(item?.length) > 1 ? 'HRS' : Number(item?.length) == 1 ? 'HR' :'MM'}`} */}
                                        </small>
                                    </td>
                                    <td className="text-end">
                                        {/* {index == 0 ? `$${item?.total}` : `$${Number(Number(item?.total) - Number(extendedDetails?.[index-1]?.total))?.toFixed(2)}`} */}
                                        {index == 0 ? `$${item?.total}` : ` $${Number(item?.total)?.toFixed(2)}`}<br />
                                        
                                        <img src={getCardIcon(item?.payment_method?item?.payment_method:'visa')} style={{height: '10px', padding: '0'}}  alt="" />
                                        {/* <img src={visa} style={{height: '10px', padding: '0'}}  alt="" /> */}
                                    </td>
                                    {/* <td className='text-center'>
                                        {`${getFormattedTime(item?.length, false)} ${Number(item?.length) > 1 ? 'HRS' : Number(item?.length) == 1 ? 'HR' :'MM'}`}
                                    </td>
                                    <td className='text-end mb-1'>
                                        {index == 0 ? `$${item?.total}` : `$${Number(Number(item?.total) - Number(extendedDetails?.[index-1]?.total))?.toFixed(2)}`}
                                        {index == 0 ? `$${item?.total}` : `$${Number(item?.grand_total)?.toFixed(2)}`}
                                    </td> */}
                                </tr>
                            )
                        })}

                        {/* <tr className='border-bottom'>
                            <td className='text-start'>
                                Started
                            </td>
                            <td className='text-center'>
                                {`${getFormattedTime(extendedDetails[0]?.length, false)} ${extendedDetails[0]?.length > 1 ? 'Hrs' : 'Hr'}`}
                            </td>
                            <td className='text-end mb-1'>
                                {`$${extendedDetails[0]?.total}`}
                            </td>
                        </tr>
                        <tr className='border-bottom mb-1'>
                            <td className='text-start'>
                                Extends
                            </td>
                            <td className='text-center'>
                                {`${getFormattedTime(extendedDetails[1]?.length, true)} ${extendedDetails[1]?.length > 1 ? 'Hrs' : 'Hr'}`}
                            </td>
                            <td className='text-end'>
                                {`$${Number(Number(extendedDetails[1]?.total) - Number(extendedDetails[0]?.total))?.toFixed(2)}`}
                            </td>
                        </tr> */}
                    </table>
                    
                </div>}
        </div>
    )
}

ExtendDetails.propTypes = {
    dispatch: PropTypes.func,
    className: PropTypes.string,
    extendedDetails: PropTypes.array,
    paymentData: PropTypes.object,
}

ExtendDetails.defaulProps = {
    dispatch: noop,
    className: '',
    extendedDetails: EMPTY_ARRAY,
    paymentData: EMPTY_OBJECT,
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(ExtendDetails)