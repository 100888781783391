export const errorMessage = {
    somethingWentWrong: 'Something went wrong, Please try again!',
    somethingWentWrongLogin: 'Something went wrong while login in, Registration succesfully done. Please login!',
    invalidPartner: 'Invalid Partner',
    invalidFacility: 'Unable to Fetch Facility , Please Try Again!',
    notFound: 'PAGE NOT FOUND',
    registeration: 'Registration completed successfully. Please login!',
    noResultound: 'No result found for these search parameters.',
    signupMobileCheckMsgs: 'A different phone number is used while creating your previous check-in, so it will not be linked with your new account. Do you want to proceed?',
    linkPhoneMessage: 'Please enter your phone number to link it with the card used',
    termsNConditions: 'I agree to the Terms of Service and Privacy Policy',

}