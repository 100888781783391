import React from 'react'
import { Circles } from 'react-loader-spinner'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_ARRAY } from '../../constants/globalConstants'
import { getBreakUpDetails } from '../../utils/helper'

function ListingDataComp(props) {
    const { loader, paymentData } = props
    return (
        <div className='ms-0 me-0'>
            {(loader) ? <div className='d-flex justify-content-center align-items-center'> <Circles
                height="20"
                width="20"
                style={{ display: 'inline-block' }}
                color="#26A8E0"
                ariaLabel="circles-loading"
                visible={true}
            /></div> : getBreakUpDetails(paymentData)?.length > 0 && (!loader) ? <table className="table table-borderless table-sm mb-0 pb-0">
                {getBreakUpDetails(paymentData)?.map((listItem, listItemIndex) => {
                    return (
                        <tr key={listItemIndex}>
                            <td>{listItem?.headerName}</td>
                            <td className="text-end">{listItem?.id === 0 ? `$${listItem?.val}` : listItem?.val}</td>
                        </tr>
                    )
                })}
            </table> : null}
        </div>
    )
}

ListingDataComp.propTypes = {
    dispatch: PropTypes.func,
    paymentData: PropTypes.array,
    loader: PropTypes.bool
}

ListingDataComp.defaulProps = {
    dispatch: noop,
    paymentData: EMPTY_ARRAY,
    loader : false
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(ListingDataComp)