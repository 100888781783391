import CONSTANTS from '../constants'
import { postRequestWithoutAuth } from '../../services'
import { APICONFIG } from '../apiConfig'

export const ticketValidation = (payload) => dispatch => {
    dispatch({ type: CONSTANTS.TICKET_VALIDATE_REQUEST })
    return postRequestWithoutAuth(APICONFIG.ticketValidateUrl, payload).then((res) => {
        dispatch({ type: CONSTANTS.TICKET_VALIDATE_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.TICKET_VALIDATE_FAILED, errorMessage: err?.errors?.message })
        return err
    })
}
