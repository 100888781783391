import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { errorMessage } from '../constants/errorMessage'

function NoData(props) {
    const {message} = props

    
    return (
        <div className='d-flex justify-content-center align-items-center m-2'>
            <p className='alert alert-danger w-100'>{message ? message : errorMessage?.somethingWentWrong}</p>
        </div>
    )
}

NoData.propTypes = {
    message: PropTypes.string
}

NoData.defaulProps = {
    message: undefined,
}

export default connect()(NoData)