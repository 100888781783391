const URL = {
	INDEX: '',
	HOME: '/:partner',
	HOME2: '/:partner/:facility',
	// HOME2: '/:partner/:route/:facility',
	CONFIRMATION_SCREEN: '/:partner/paymentResponse',
	CONFIRMATION: '/paymentResponse',
	NAVIGATING: 'https://demo-permit.parkengage.com/navigating',
	SCAN_VALIDATE: '/validate',
	SCAN_TOKEN_VALIDATE: '/:partner/validate/:ticket_id',
	TICKET_EXTEND: '/:partner/extend/:ticket_id',
	EXTEND_SUCCESS: '/:partner/extend-success',
	EXTENDSUCCESS: '/extend-success',
	TICKET: '/:partner/ticket/:ticketId',
	TICKET_VIEW: '/ticket',
	SEARCH: 'search-ticket',
	DETAILS: '/:partner/ticket-details',
	INVALID: '*',
	EXTERNAL: 'https://www.npmjs.com/package/qrcode.react',
	LOGIN_INDEX: '/:partner/login',
	SING_UP: '/:partner/signup',
	FORFOT_PASSWORD: '/:partner/reset-password/:token_id',
	SEND_EMAIL_RESET_PASSWORD: '/:partner/send-email',
	LOGIN_INDEX_FACILITY: '/:partner/:facility/login',
	SING_UP_FACILITY: '/:partner/:facility/signup',
	FORFOT_PASSWORD_FACILITY: '/:partner/:facility/reset-password/:token_id',
	SEND_EMAIL_RESET_PASSWORD_FACILITY: '/:partner/:facility/send-email',

}

export default URL
