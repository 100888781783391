import React, { useEffect, useState } from 'react'
import { EMPTY_ARRAY } from '../../constants/globalConstants'
import { useParams, useNavigate } from 'react-router-dom'
import URL from '../../constants/urls'

import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import Loader from '../../global/Loader'
import Receipt from '../Receipt/Receipt'
import { filterUnique } from '../../utils/helper'



function TicketList(props) {

    const {loader, ticketList, notify} = props
    const [activeReceipt, setActiveReceipt] = useState(EMPTY_ARRAY)
    const [inactiveReceipt, setInActiveReceipt] = useState(EMPTY_ARRAY)
    const navigate = useNavigate()
    let { partner } = useParams()


const handleDetails  = (item) =>{
    // localStorage.setItem('paymentRecipt', JSON.stringify(item))
    navigate(`/${partner}${URL?.TICKET_VIEW}/${item?.ticket_number}`)
    
}

    useEffect(() => {
        let active = []
        let inactive = []

        ticketList?.map((val) => {
            if (val?.is_expired || val?.is_checkout == '1' || val?.is_closed == '1')
                inactive.push(val)
            else
                active.push(val)
        })
        setActiveReceipt(filterUnique(active, 'ticket_number'))
        setInActiveReceipt(filterUnique(inactive, 'ticket_number'))

    }, [ticketList])


    return (
        <div className='Pave'>
             
            {(loader && ticketList?.length < 1) ?
                <div className='d-flex align-items-center justify-content-center vh-70'><Loader /></div>  : (ticketList && ticketList?.length) > 0 &&
                    <div>
                    
                      
                    {(activeReceipt && activeReceipt.length > 0) && <div>
                        <div className='bg-second h-100 pb-2 pt-1'><span className='small text-primary ms-2 mt-2'>Active Parking Sessions</span></div>
                        <div className='border-0'>
                            <div className='ms-2 me-2'>
                                {activeReceipt && activeReceipt.map((value, index) => {
                                    return (
                                        <Receipt handleDetails={handleDetails} receipt={value} key={index} notify={notify}/>
                                    )
                                })

                                }

                            </div>
                        </div>
                    </div>}
                    {(inactiveReceipt && inactiveReceipt.length > 0) && <div>
                        <div className='bg-second h-100 pb-2 pt-1'><span className='small text-primary ms-2 mt-2'>Past Parking Sessions</span></div>
                        <div className='border-0'>
                            <div className='ms-2 me-2'>
                                {inactiveReceipt && inactiveReceipt.map((value, index) => {
                                    return (
                                        <Receipt handleDetails={handleDetails} receipt={value} key={index} notify={notify}/>
                                    )
                                })

                                }

                            </div>
                        </div>
                    </div>}
                </div>}
        </div>
    )
}

TicketList.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    ticketList: PropTypes.array
}

TicketList.defaulProps = {
    dispatch: noop,
    loader: true,
    notify: noop,
    ticketList: EMPTY_ARRAY
}

function mapStateToProps({ userDetailsReducer }) {
    return {
        loader: userDetailsReducer?.loaderDownload
    }
}
export default connect(mapStateToProps)(TicketList)