
import CONSTANTS from '../constants'
import {postRequest } from '../../services'
import { APICONFIG } from '../apiConfig'
import { getConfigDetails } from '../../services/config'
import { loggedInUserData } from '../../utils/helper'


export const addEventLog = (data, title) => (dispatch) => {
    const payload = {
        'error_message': JSON.stringify(data),
        'facility_id': getConfigDetails().CLIENT_SECRET,
        'event_name': title,
        'user_id': loggedInUserData()?.user?.id,
        'user_ip': ''
    }
    dispatch({ type: CONSTANTS.ADD_EVENT_LOG_REQUEST })
    return postRequest(APICONFIG.storeEventLog, payload).then((res) => {
        dispatch({ type: CONSTANTS.ADD_EVENT_LOG_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.ADD_EVENT_LOG_FAILURE })
        return err
    })
}