import React from 'react'
// import logos from '../../assets/img/logos.svg'

function Footer() {
    return (
        <div className='footer text-center m-auto mb-2 d-flex flex-column align-items-end'>
            {/* <span className='d-flex align-items-center justify-content-center'><span className='fw-bold me-2'>Powered By</span> <img className={'img-res'} src={logos} alt={'PARKENGAGE'}></img></span> */}
        </div>
    )
}

export default Footer