import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
const cardnumbermask = createDefaultMaskGenerator('9999')
const expirymask = createDefaultMaskGenerator('99/99')
const phoneMask = createDefaultMaskGenerator('(999) 999-9999')
import 'react-toastify/dist/ReactToastify.css'
import { getTicketList } from '../../redux/actions/userDetails'
import 'react-datepicker/dist/react-datepicker.css'
import { Circles } from 'react-loader-spinner'
// import Ticket from '../main'
import NoData from '../../components/NoData'
import { errorMessage } from '../../constants/errorMessage'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import TicketList from '../ticketList/TicketList'
import Loader from '../../global/Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { getPartnerDetails } from '../../utils/helper'
// import logos from '../../assets/img/logos.svg'

function SearchTicket(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm()
  const { dispatch, loader, notify, loaderDownload } = props
  // const [phoneNumber, setPhoneNumber] = useState('')
  // const [cardNumber, setCardNumber] = useState('')
  // const [cardExpiry, setCardExpiry] = useState('')
  // const [ticketDetails, setTicketDetails] = useState('')
  const [searchByCard, setSearchByCard] = useState(true)
  const [searchByPhone, setSearchByPhone] = useState(false)
  const [searchByLicencePlate, setSearchByLicencePlate] = useState(false)
  const [searchByTicket, setSearchByTicket] = useState(false)
  const [ticketList, setTicketList] = useState(EMPTY_ARRAY)
  const [error, setError] = useState(null)
  const [formPayload, setFormPayload] = useState(EMPTY_OBJECT) //to be used by lazyload
  const [page, setPage] = useState(1)
  const [entryDateTime, setEntryDateTime] = useState(new Date(moment().startOf('month').format('Y-MM-DD')))
  const [exitDateTime, setExitDateTime] = useState(new Date(moment().endOf('month').format('Y-MM-DD')))

  const handleEntryTime = (event) => {

    let dt1 = event
    let dt2 = exitDateTime
    setEntryDateTime(event)
    setValue('entry_time', event)
    if (dt1 >= dt2) {
      setExitDateTime(event)
      setValue('exit_time', event)
    }


  }

  const handleExitTime = (event) => {
    if (event == null) {
      setExitDateTime(event)
      setValue('exit_time', event)
    }
    if (event) {
      let dt1 = entryDateTime
      let dt2 = event
      if (dt2 >= dt1) {
        setExitDateTime(event)
        setValue('exit_time', event)

      } else {
        notify('error', 'End time should not be less then start time!')
        // setIsLoading(true)

      }
    }
  }

  // const handleExitTime = (event) => {
  //   setExitDateTime(event)
  //   setValue('exit_time',event)
  // }


  const onSubmit = (data) => {
    setError('')
    //console.log('payload time',moment(data?.entry_time).format('Y-MM-DD HH:mm:ss'))
    // const payload = { ...data, checkin_date: data?.checkin_date ? moment(data?.checkin_date).format('Y-MM-DD') : '', card_number: cardNumber, card_expiry: cardExpiry }
    // const isEmpty = Object.values(payload).every(x => x === null || x === '')
    setTicketList('')
    const payload = {
      card_expiry: data?.card_expiry,
      card_number: data?.card_number,
      checkin_date: '',
      phone: data?.phone,
      ticket_number: data?.ticket_number,
      license_plate: data?.license_plate,
      entry_time: (!searchByTicket) ? (entryDateTime ? moment(entryDateTime).format('Y-MM-DD HH:mm:ss') : '') : '',
      exit_time: (!searchByTicket) ? (exitDateTime ? moment(exitDateTime).format('Y-MM-DD HH:mm:ss') : '') : '',
      role: getPartnerDetails()?.roles?.[0],
      id: getPartnerDetails()?.id,
    }
    if (entryDateTime && exitDateTime == null) {
      notify('error', 'Please select exit time')
      return
    } else if (exitDateTime && entryDateTime == null) {
      notify('error', 'Please select entry time')
      return
    } else {
      if (exitDateTime && entryDateTime) {
        if (exitDateTime < entryDateTime) {
          notify('error', 'Exit time should not be less then entry time!')
          return
        }
      }
      setFormPayload(payload)
      if ((data?.card_number != '' && data?.card_expiry != '') || data?.phone != '' || data?.ticket_number != '' || data?.license_plate != '') {
        dispatch(getTicketList(payload))
          .then((res) => {
            if (res?.status === 201 || res?.status === 200) {
              if (res?.data && res?.data != null) {
                if (res?.data == '') {
                  notify('error', errorMessage?.noResultound)
                }
                // notify('success', 'T successfully')
                setPage(1)
                setTicketList(res?.data?.data)
                if (res?.data?.data?.length < 1) {
                  setError(errorMessage?.noResultound)
                }
              } else {
                setError(
                  res?.errors?.message
                    ? res?.errors?.message
                    : errorMessage?.somethingWentWrong
                )
                notify(
                  'error',
                  res?.errors?.message
                    ? res?.errors?.message
                    : errorMessage?.somethingWentWrong
                )
              }
            } else {
              setTicketList('')
              setError(
                res?.errors?.message
                  ? res?.errors?.message
                  : errorMessage?.somethingWentWrong
              )
              notify(
                'error',
                res?.errors?.message
                  ? res?.errors?.message
                  : errorMessage?.somethingWentWrong
              )
            }
          })
          .catch(() => {
            setTicketList('')
            setError(errorMessage?.somethingWentWrong)
            notify('error', errorMessage?.somethingWentWrong)
          })
      } else {
        notify('error', 'Please fill the search details')
      }
    }
  }

  // const handleCardNumber = (event) => {
  // 	setCardNumber(event)
  // }
  // const handlePhoneNumber = (event) => {
  // 	setPhoneNumber(event)
  // }

  // const handleCardExpiry = (event) => {
  // 	setCardExpiry(event)
  // }
  const handleSearchByEvent = (e, search) => {
    setTicketList('')
    reset({
      card_expiry: '',
      card_number: '',
      phone: '',
      ticket_number: '',
      license_plate: '',
      entry_time: new Date(),
      exit_time: new Date(),
    })
    //  setEntryDateTime(new Date())
    //  setExitDateTime(new Date())
    setSearchByCard(false)
    setSearchByPhone(false)
    setSearchByLicencePlate(false)
    setSearchByTicket(false)
    search == 'card' ? setSearchByCard(true) :
      (search == 'phone') ? setSearchByPhone(true) :
        (search == 'licencePlate') ? setSearchByLicencePlate(true) :
          (search == 'ticket') ? setSearchByTicket(true) :
            setSearchByCard(true)

  }

  const getAllReceipts = (page) => {
    dispatch(getTicketList(formPayload, page))
      .then((res) => {
        if (res?.status === 201 || res?.status === 200) {
          if (res?.data && res?.data != null) {
            if (res?.data == '') {
              notify('error', errorMessage?.noResultound)
            }
            // notify('success', 'T successfully')
            let receipts
            const data = res?.data?.data
            receipts = [...ticketList, ...data]
            setTicketList(receipts)
          } else {
            setError(
              res?.errors?.message
                ? res?.errors?.message
                : errorMessage?.somethingWentWrong
            )
            notify(
              'error',
              res?.errors?.message
                ? res?.errors?.message
                : errorMessage?.somethingWentWrong
            )
          }
        } else {
          setTicketList('')
          setError(
            res?.errors?.message
              ? res?.errors?.message
              : errorMessage?.somethingWentWrong
          )
          notify(
            'error',
            res?.errors?.message
              ? res?.errors?.message
              : errorMessage?.somethingWentWrong
          )
        }
      })
      .catch(() => {
        setTicketList('')
        setError(errorMessage?.somethingWentWrong)
        notify('error', errorMessage?.somethingWentWrong)
      })
    setPage(page + 1)
  }
  return (
    <div>
      <div className="card bg-white rounded border-0">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-2">
              <div className="row mb-2 p-0 ms-0 me-0">
                {/* <label className='switch'>
											<input type="checkbox" disabled={loader} checked={searchByCard} onChange={()=>handleSearchByEvent()} data-toggle="toggle"/>
											<span className='slider round'></span>
										</label> */}
                <span className="fw-bold d-block ms-0 ps-0 ps-2  me-0 pe-2">Search By</span>
                <div className="col-6 ms-0 me-0 pe-0 ps-0 d-flex align-items-center mb-2">
                  <input
                    className='checkboxWidth'
                    type="checkbox"
                    disabled={loader}
                    checked={searchByCard}
                    onChange={(e) => handleSearchByEvent(e, 'card')}
                    data-toggle="toggle"
                  />
                  <span className="small text-black">Card Details</span>
                </div>
                <div className="col-6 ms-0 me-0 pe-0 ps-0 d-flex align-items-center mb-2">
                  <input
                    className='checkboxWidth'
                    type="checkbox"
                    disabled={loader}
                    checked={searchByPhone}
                    onChange={(e) => handleSearchByEvent(e, 'phone')}
                    //onChange={() => handleSearchByPhone()}
                    data-toggle="toggle"
                  />
                  <span className="small text-black">Phone Number</span>
                </div>
                <div className="col-6 ms-0 me-0 pe-0 ps-0 d-flex align-items-center">
                  <input
                    className='checkboxWidth'
                    type="checkbox"
                    disabled={loader}
                    checked={searchByLicencePlate}
                    onChange={(e) => handleSearchByEvent(e, 'licencePlate')}
                    // onChange={() => handleSearchByLicencePlate()}
                    data-toggle="toggle"
                  />
                  <span className="small text-black">License Plate</span>
                </div>
                <div className="col-6 ms-0 me-0 pe-0 ps-0 d-flex align-items-center">
                  <input
                    className='checkboxWidth'
                    type="checkbox"
                    disabled={loader}
                    checked={searchByTicket}
                    onChange={(e) => handleSearchByEvent(e, 'ticket')}
                    // onChange={() => handleSearchByTicket()}
                    data-toggle="toggle"
                  />
                  <span className="small text-black">Ticket Number</span>
                </div>
              </div>
              {/* Display if Card Number Searching is Enabled */}
              {searchByCard ? (
                <div className="col col-sm-12 col-12 ms-0 ps-2 me-0 pe-2">
                  <div className="form-group mb-2">
                    <label htmlFor="card_number">Card Information</label>
                  </div>
                  <div className="input-group form-group mb-2">
                    <Controller
                      name="card_number"
                      type="text"
                      id="card_number"
                      control={control}
                      // rules={{ required: (cardExpiry.length === 4 && cardNumber.length !== 4) ? true : false, maxLength: 4 }}
                      rules={{
                        required: {
                          value: true,
                          message: 'Card Number is required',
                        },
                        minLength: {
                          value: 4,
                          message: 'Minimum 4 characters allowed',
                        },
                        maxLength: {
                          value: 4,
                          message: 'Maximum 4 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <MaskedInput
                          maskGenerator={cardnumbermask}
                          autoComplete="off"
                          pattern="[0-9 ]{4}"
                          maxLength="4"
                          {...field}
                          id="card_number"
                          title="Card no. should be 15-16 digit numberic"
                          className="form-control specialBorder"
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              'Please enter a valid card number'
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity('')}
                          placeholder="Last 4 digit"
                        // onChange={(event) => field.onChange(handleCardNumber(event))}
                        />
                      )}
                    />

                    {/* expiry input filled  */}
                    <Controller
                      name="card_expiry"
                      type="text"
                      id="card_expiry"
                      control={control}
                      // rules={{ required: (cardNumber.length === 4 && cardExpiry.length !== 4) ? true : false, maxLength: 5 }}
                      rules={{
                        required: {
                          value: true,
                          message: 'Card Expiry is required',
                        },
                        minLength: {
                          value: 4,
                          message: 'Minimum 4 characters allowed',
                        },
                        maxLength: {
                          value: 4,
                          message: 'Maximum 4 characters allowed',
                        },
                      }}
                      render={({ field }) => (
                        <MaskedInput
                          maskGenerator={expirymask}
                          autoComplete="off"
                          pattern="(?:0?[1-9]|1[0-2])/[0-9 ]{2}"
                          maxLength="5"
                          {...field}
                          id="card_expiry"
                          className="form-control"
                          onInvalid={(e) =>
                            e.target.setCustomValidity(
                              'Please enter mm/yy format card expiry date'
                            )
                          }
                          onInput={(e) => e.target.setCustomValidity('')}
                          placeholder="mm/yy"
                        // onChange={(event) => field.onChange(handleCardExpiry(event))}
                        />
                      )}
                    />
                  </div>
                  <div className='row'><div className='col-6 col-sm-6 pe-0 me-0'>{errors?.card_number && (
                    <span className="text-danger small">
                      {errors?.card_number?.message}
                    </span>
                  )}</div>
                    <div className='col-6 col-sm-6 ps-0 ms-0'>{errors?.card_expiry && (
                      <span className="text-danger small">
                        {errors?.card_expiry?.message}
                      </span>
                    )}</div></div>

                  {/* search by entry exit time */}




                  {/*end  search by entry exit time */}
                </div>
              ) : /* Display if Phone Number Searching is Enabled */
                searchByPhone ? (
                  <div className="col col-sm-12 col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="card_number">Phone Number</label>
                    </div>
                    <div className="input-group mb-2 form-group">
                      <Controller
                        name="phone"
                        type="text"
                        id="phone"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                          minLength: {
                            value: 10,
                            message: 'Minimum 10 characters allowed',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Maximum 10 characters allowed',
                          },
                        }}
                        render={({ field }) => (
                          <MaskedInput
                            maskGenerator={phoneMask}
                            autoComplete="off"
                            maxLength="14"
                            {...field}
                            id="phone"
                            title="Phone number should be 10 digit numberic"
                            className="form-control"
                            onInvalid={(e) =>
                              e.target.setCustomValidity(
                                'Please enter a valid phone number'
                              )
                            }
                            onInput={(e) => e.target.setCustomValidity('')}
                            placeholder="Phone Number"
                          // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                          />
                        )}
                      />
                    </div>
                    {errors?.phone && (
                      <span className="text-danger small">
                        {errors?.phone?.message}
                      </span>
                    )}
                  </div>
                ) : searchByTicket ? (
                  <div className="col col-sm-12 col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="card_number">Ticket Number</label>
                    </div>
                    <div className="input-group mb-2 form-group">
                      <Controller
                        name="ticket_number"
                        type="text"
                        id="ticket_number"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/,
                            message: 'Please enter valid ticket number'
                          },
                          minLength: {
                            value: 8,
                            message: 'Minimum 8 characters allowed',
                          },
                          maxLength: {
                            value: 11,
                            message: 'Maximum 11 characters allowed',
                          },
                        }}
                        render={({ field }) => (
                          <input
                            type={'text'}
                            autoComplete="off"
                            className="form-control"
                            {...field}
                            id="ticket_number"
                            placeholder="Ticket Number"

                          // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                          />
                        )}
                      />
                    </div>
                    {errors?.ticket_number && (
                      <span className="text-danger small">
                        {errors?.ticket_number?.message}
                      </span>
                    )}
                  </div>
                ) : searchByLicencePlate ? (
                  <div className="col col-sm-12 col-12">
                    <div className="form-group mb-2">
                      <label htmlFor="card_number">Licence Plate</label>
                    </div>
                    <div className="input-group mb-2 form-group">
                      <Controller
                        name="license_plate"
                        type="text"
                        id="license_plate"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'This field is required',
                          },
                          minLength: {
                            value: 3,
                            message: 'Minimum 3 characters allowed',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Maximum 10 characters allowed',
                          },
                          pattern: {
                            value: /^[a-zA-Z0-9\s]*$/,
                            message: 'Please enter valid license plate'
                          }
                        }}
                        render={({ field }) => (
                          <input
                            type={'text'}
                            autoComplete="off"
                            {...field}
                            id="license_plate"
                            title="Phone number should be 10 digit numberic"
                            className="form-control"
                            placeholder="Licence Plate"
                          // onChange={(event) => field.onChange(handlePhoneNumber(event))}
                          />
                        )}
                      />
                    </div>
                    {errors?.license_plate && (
                      <span className="text-danger small">
                        {errors?.license_plate?.message}
                      </span>
                    )}
                  </div>
                ) : (
                  ''
                )}

              {/* search by entry exit time */}


              {!searchByTicket && <div className="form-group mb-2 m-0 p-0 ps-2 pe-2">

                <div className="row g-0">
                  <div className='col col-sm-6 col-6'><label htmlFor="card_number">Entry Time</label>
                    <Controller
                      name="entry_time"
                      type="text"
                      id="entry_time"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={entryDateTime}
                          onChange={(date) => handleEntryTime(date)}
                          className="form-control specialBorder borderFix "
                          autoComplete="off"
                          id="entry_time"
                          name='entry_time'
                          showTimeSelect
                          isClearable
                          dateFormat="MMMM d, yyyy h:mm aa"
                        //maxDate={exitDateTime}
                        />

                      )}
                    />

                  </div>
                  <div className='col col-sm-6 col-6'><label htmlFor="card_number">Exit Time</label>

                    <Controller
                      name="exit_time"
                      type="text"
                      id="exit_time"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          selected={exitDateTime}

                          onChange={(date) => handleExitTime(date)}
                          //onChange={(event) => field.onChange(handleCardNumberDD(event))}
                          className="form-control specialBorder borderFix1"
                          autoComplete="off"
                          id="exit_time"
                          name='exit_time'
                          showTimeSelect
                          isClearable
                          dateFormat="MMMM d, yyyy h:mm aa"
                          minDate={entryDateTime}
                          minTime={moment(entryDateTime).format('Y-MM-DD') == moment(exitDateTime).format('Y-MM-DD') ? new Date(0, 0, 0, new Date(entryDateTime).getHours(), new Date(entryDateTime).getMinutes()) : new Date(0, 0, 0, 0, 0)} // 7:30am
                          maxTime={new Date(0, 0, 0, 23, 59)} // 4:30pm
                        />

                      )}
                    />
                  </div>

                </div>


              </div>}

              {/*end  search by entry exit time */}

              <div className="ms-0 ps-2 me-0 pe-2 col-sm-2 mt-3">
                <div className="row">
                  <div className="col">
                    <button
                      type="submit"
                      name="submit"
                      className="btn mt-2 w-100 bg-primary text-white"

                      onClick={handleSubmit}
                    >
                      {' '}
                      <div className="d-flex justify-content-center align-items-center">
                        <span>Search</span>
                        <span className="ms-2">
                          {loader ? (
                            <Circles
                              height="20"
                              width="20"
                              style={{ display: 'inline-block' }}
                              color="#ffffff"
                              ariaLabel="circles-loading"
                              visible={true}
                            />
                          ) : null}
                        </span>
                      </div>
                    </button>
                  </div>
                  {/* {ticketList?.ticket_number && <div className='col-6'>
                                        <button 
											type="button" 
											name="submit" 
											className="btn mt-2 w-100" 
											style={{ backgroundColor: '#27A8E1', color: 'white' }} 
											onClick={() => handleDownloadTicket(ticketList?.ticket_number)} > 
											<div className='d-flex justify-content-center align-items-center'>
												<span>Download <i className="fa fa-download"></i></span>
												<span className='ms-2'>
													{loaderDownload ? <Circles
														height="20"
														width="20"
														style={{ display: 'inline-block' }}
														color="#ffffff"
														ariaLabel="circles-loading"
														visible={true}
													/> : null}
												</span>
											</div>
                                        </button>
                                    </div>} */}
                </div>
              </div>
            </div>
          </form>

          <InfiniteScroll
            dataLength={ticketList?.length}
            next={() => getAllReceipts(page)}
            hasMore={true}
            loader={(loader && ticketList?.length > 0) && <Loader isMargin />}
          >
            {ticketList?.length > 0 ? (
              <TicketList ticketList={ticketList} notify={notify} />
            ) : (
              error && <NoData message={error} />
            )}

          </InfiniteScroll>

        </div>
      </div>
      {loaderDownload && <div className='top-0 justify-content-center position-fixed w-100 h-100 zIndexHigh bg-black d-flex align-items-center opacity text-center opacity-25 text-white'>
        <i className='fa fa-spinner fa-spin'></i>
      </div>}
    </div>
  )
}

SearchTicket.propTypes = {
  dispatch: PropTypes.func,
  loader: PropTypes.bool,
  loaderSms: PropTypes.bool,
  loaderDownload: PropTypes.bool,
  notify: PropTypes.func,
}

SearchTicket.defaulProps = {
  dispatch: noop,
  loader: false,
  notify: noop,
}

function mapStateToProps({ userDetailsReducer }) {
  return {
    loader: userDetailsReducer?.loader,
    loaderSms: userDetailsReducer?.loaderSms,
    loaderDownload: userDetailsReducer?.loaderDownload,
  }
}
export default connect(mapStateToProps)(SearchTicket)
