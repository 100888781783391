import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { useState } from 'react'
import { Circles } from 'react-loader-spinner'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import MiniHeader from '../../components/global/MiniHeader'
import { useForm } from 'react-hook-form'
import { Card, Container, Form, Image, InputGroup } from 'react-bootstrap'
import { restrictInValidCharPassword } from '../../utils/helper'
import emailImage from '../../assets/img/email.png'
import keyImage from '../../assets/img/key.png'
import { UpdateUserpassword } from '../../redux/actions/singinAndSingUp'
function ForgotPassword(props) {
    const { register, handleSubmit, formState: { errors }, reset, watch, setValue } = useForm()

    const dispatch = useDispatch()
    const { token_id } = useParams()

    const { notify, loader } = props

    const [passwordShown, setPasswordShown] = useState(false)
    const [cpasswordShown, setCPasswordShown] = useState(false)


    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setPasswordShown(passwordShown ? false : true) }
        if (key === 'password_confirmation') { setCPasswordShown(cpasswordShown ? false : true) }
    }




    const handleReset = (data) => {

        let formData = {
            email: data?.email,
            password: data?.password,
            password_confirmation: data?.password_confirmation,
            token: token_id
        }

        dispatch(UpdateUserpassword(formData)).then((res) => {
            if (res?.status == '201') {
                notify('success', 'Password changed Successfully')
                reset(formValues => ({ ...formValues, 'email': '', 'password': '', 'password_confirmation': '' }))
            } else {
                notify('error', res?.errors?.message)
            }

        })

    }



    return (
        <div>
            <MiniHeader title={'Reset Password'} />

            <div className="login-popup">
                <div className='container' >
                    <div className="">
                        <div className="row justify-content-center">
                            <div className="col-sm-7">

                                <div className='login-rt border mt-4'>
                                    <div className="alert alert-primary m-2">
                                        {'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-). '}
                                    </div>
                                    <Form className='mt-5' onSubmit={handleSubmit(handleReset)}>
                                        <Container>
                                            <Card className='border-0 '>
                                                <InputGroup className='border rounded mb-2'>
                                                    <InputGroup.Text id="inputLoginImage iconWidth"><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
                                                    <Form.Control
                                                        type="email"
                                                        name='email'
                                                        aria-describedby="basic-addon1"
                                                        className='form-control inputLogin'
                                                        id='loginInput'
                                                        maxLength='40'
                                                        minLength='1'
                                                        placeholder="Enter Email Address"
                                                        {...register('email', {
                                                            required: { value: true, message: 'This field is required' },
                                                            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Please enter a valid email address' },
                                                        })}
                                                    />
                                                </InputGroup>
                                                {(errors?.email) && <span className='text-danger header-s'>{errors?.email?.message}</span>}
                                                <InputGroup className='border rounded myiconset mb-2'>
                                                    <InputGroup.Text id="inputLoginImage iconWidth"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                                                    <Form.Control
                                                        type={passwordShown ? 'text' : 'password'}
                                                        name='password'
                                                        className="form-control passwordInput inputLogin"
                                                        id="loginInput"
                                                        placeholder="Enter Password"
                                                        aria-describedby="basic-addon1"
                                                        {...register('password', {
                                                            required: { value: true, message: 'This field is required' },
                                                            pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-])[^()\\`~:;"/<>]{8,15}$/, message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' },
                                                            onChange: (e) => setValue(e.target.name, restrictInValidCharPassword(e.target.value))
                                                        })}
                                                    />
                                                    <i className={passwordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                                                </InputGroup>
                                                {(errors?.password) && <span className='text-danger header-s'>{errors?.password?.message}</span>}
                                                <InputGroup className='border rounded myiconset'>
                                                    <InputGroup.Text id="inputLoginImage iconWidth"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                                                    <Form.Control
                                                        type={cpasswordShown ? 'text' : 'password'}
                                                        name='password_confirmation'
                                                        className="form-control passwordInput inputLogin"
                                                        id="loginInput"
                                                        placeholder="Confirm Password"
                                                        aria-describedby="basic-addon1"
                                                        {...register('password_confirmation', {
                                                            required: { value: true, message: 'This field is required' },
                                                            pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-])[^()\\`~:;"/<>]{8,15}$/, message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' },
                                                            validate: (val) => {
                                                                if (watch('password') != val) {
                                                                    return 'Your passwords does not match'
                                                                }
                                                            },
                                                            onChange: (e) => setValue(e.target.name, restrictInValidCharPassword(e.target.value))
                                                        })}
                                                    />
                                                    <i className={cpasswordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password_confirmation')}></i>
                                                </InputGroup>
                                                {(errors?.password_confirmation) && <span className='text-danger header-s'>{errors?.password_confirmation?.message}</span>}
                                            </Card>
                                        </Container>



                                        <Container className="mt-3">
                                            <button type="submit" name="submit" disabled={loader} className="btn w-100 my-4 text-white bg-primary">
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <span>Reset Password </span>  <span className='ms-2'>
                                                        {(loader) ? <Circles
                                                            height="20"
                                                            width="20"
                                                            style={{ display: 'inline-block' }}
                                                            color="#ffffff"
                                                            ariaLabel="circles-loading"
                                                            visible={true}
                                                        /> : null}
                                                    </span>
                                                </div>
                                            </button>
                                        </Container>



                                    </Form>






                                </div>
                            </div>
                            {/* <BottomLogo className="bg-opacity-10 pe-4 logo_style" /> */}
                            {/* <LoginModal loginModalOpen={this.state.loginModalOpen} singUpModalOpen={this.state.singUpModalOpen} handleLoginIn={this.handleLoginIn} handleCreateAccount={this.handleCreateAccount} /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
ForgotPassword.propTypes = {
    dispatch: PropTypes.func,
    notify: PropTypes.func,
    loader: PropTypes.func,
}

ForgotPassword.defaulProps = {
    dispatch: noop,
    notify: noop,
    loader: noop,
}

function mapStateToProps({ singInAndSingUp }) {
    return {
        loader: singInAndSingUp?.loader
    }
}
export default connect(mapStateToProps)(ForgotPassword)