import CONSTANTS from '../constants'
import { postRequest } from '../../services'
import { APICONFIG } from '../apiConfig'
import { addEventLog } from './EventsLog'


// sing_in
export const userSingIn = (creds) => (dispatch) => {
  dispatch(addEventLog(creds, CONSTANTS.LOGIN_REQUEST))
  dispatch({ type: CONSTANTS.LOGIN_REQUEST })
  return postRequest(APICONFIG.singInUrl, creds).then((res) => {
    let resObject = { ...creds, ...res }
    dispatch(addEventLog(resObject, CONSTANTS.LOGIN_SUCCESS))
    dispatch({ type: CONSTANTS.LOGIN_SUCCESS, data: res })
    return res
  }).catch((err) => {
    let errObject = { ...creds, ...err }
    dispatch(addEventLog(errObject, CONSTANTS.LOGIN_FAILURE))
    dispatch({ type: CONSTANTS.LOGIN_FAILURE })
    return err
  })
}

// sing_up

export const userSingUp = (creds) => (dispatch) => {
  dispatch(addEventLog(creds, CONSTANTS.SINGUP_REQUEST))
  dispatch({ type: CONSTANTS.SINGUP_REQUEST })
  return postRequest(APICONFIG.singupUrl, creds).then((res) => {
    let resObject = { ...creds, ...res }
    dispatch(addEventLog(resObject, CONSTANTS.SINGUP_SUCCESS))
    dispatch({ type: CONSTANTS.SINGUP_SUCCESS, data: res })
    return res
  }).catch((err) => {
    let errObject = { ...creds, ...err }
    dispatch(addEventLog(errObject, CONSTANTS.SINGUP_FAILURE))
    dispatch({ type: CONSTANTS.SINGUP_FAILURE })
    return err
  })
}

export const sendEmailResetPassword = (payload) => (dispatch) => {
  dispatch(addEventLog(payload, CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST))
  dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_REQUEST })
  return postRequest(APICONFIG.sendEmailResetPassword, payload).then((res) => {
    let resObject = { ...payload, ...res }
    dispatch(addEventLog(resObject, CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS))
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    let errObject = { ...payload, ...err }
    dispatch(addEventLog(errObject, CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE))
    dispatch({ type: CONSTANTS.SEND_EMAIL_RESET_PASSWORD_FAILURE })
    return err
  })
}


// RESET PASSWORD
export const UpdateUserpassword = (payload) => (dispatch) => {
  dispatch(addEventLog(payload, CONSTANTS.RESET_PASSWORD_REQUEST))
  dispatch({ type: CONSTANTS.RESET_PASSWORD_REQUEST })
  return postRequest(APICONFIG.resetPassword, payload).then((res) => {
    let resObject = { ...payload, ...res }
    dispatch(addEventLog(resObject, CONSTANTS.RESET_PASSWORD_SUCCESS))
    dispatch({ type: CONSTANTS.RESET_PASSWORD_SUCCESS, data: res })
    return res
  }).catch((err) => {
    let errObject = { ...payload, ...err }
    dispatch(addEventLog(errObject, CONSTANTS.RESET_PASSWORD_FAILURE))
    dispatch({ type: CONSTANTS.RESET_PASSWORD_FAILURE })
    return err
  })
}


export const handleUserLogOut = () => {
  localStorage.clear()
}