import React from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
// import { Container } from 'react-bootstrap'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import Loader from '../../global/Loader'

function AmountPaid(props) {
    const { className, sectionKey, paymentData, isInfo , setIsDisplayDetails, hideTaxIncluded , isTokenApiStarts} = props
    // const [discountedTotal, setDiscountedTotal] = useState(0)
    const handleDetailsDisplay = () => {
        setIsDisplayDetails(true)
    }

    // useEffect(() => {
    //     const discountTotal = paymentData?.discount_amount ? paymentData?.grand_total == 0 ? paymentData?.grand_total : (Number(paymentData?.grand_total) - Number(paymentData?.discount_amount)).toFixed(2) : paymentData?.grand_total ? paymentData?.grand_total : '0.00'
    //     setDiscountedTotal(discountTotal)
    // },[EMPTY_ARRAY, paymentData])

    return (
        <div>
            {sectionKey === 'paymantResponse' ?
                <div className={`${className}`}>
                    <div className="col-auto">
                        <p className="mb-0 text-primary">Amount Paid</p>
                        {!hideTaxIncluded && <small>(Tax Included)</small>}
                        {paymentData?.reservation?.thirdparty_integration_id  === '1' && <small>(Paid at Aggregator)</small>}
                    </div>
                    <div className="col-auto text-end">
                        {isTokenApiStarts ? <Loader hideMargin size={25}/>: <h4 className="mb-0">{`$${(paymentData?.amount_paid && Number(paymentData?.amount_paid) > 0) ? Number(paymentData?.amount_paid).toFixed(2) : '0.00'}`}
                            {(isInfo && Number(paymentData?.amount_paid) > 0) && <i className='fa fa-info-circle text-primary ms-1' onClick={() => handleDetailsDisplay()}></i>}
                        </h4>}
                    </div>
                </div> :
                <div className={className}>
                    <div className='m-0 col-12 text-primary'>
                        <div className={`${isInfo ? 'text-white ' : ' ' }list-group-item d-flex justify-content-between align-items-center`}>
                            <span className='fw-normal small'><h6 className='m-0 p-0 d-inline float-start'>
                                {(paymentData.refund_status == 'Canceled' || paymentData?.refund_status == 'Refunded') ? 'Amount Paid' : 'Amount Due'}
                            </h6></span>
                            <span className='text-end lh-1'>
                            {isTokenApiStarts ? <Loader hideMargin size={32} color={'#ffffff'}/>: <div><h6 className='m-0 p-0 d-inline float-start'>{`$${((paymentData.refund_status == 'Canceled' || paymentData?.refund_status == 'Refunded') && paymentData?.refund_amount) ? Number(paymentData?.refund_amount).toFixed(2) : (paymentData?.payable_amount && Number(paymentData?.payable_amount) > 0) ? Number(paymentData?.payable_amount).toFixed(2) : '0.00'}`}
                            {!hideTaxIncluded && <span className='d-block small header-s'><small className='m-0 p-0 fw-normal small'>(Tax Included)
                                </small></span>}
                                </h6>
                                
                                {(isInfo && Number(paymentData?.payable_amount) > 0) && <i className='fa fa-info-circle text-white ms-1' onClick={() => handleDetailsDisplay()}></i>}
                                </div>}

                            </span>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

AmountPaid.propTypes = {
    dispatch: PropTypes.func,
    className: PropTypes.string,
    faciltyData: PropTypes.object,
    sectionKey: PropTypes.string,
    paymentData: PropTypes.object,
    isInfo: PropTypes.bool,
    setIsDisplayDetails: PropTypes.func,
    hideTaxIncluded: PropTypes.bool,
    isTokenApiStarts: PropTypes.bool,
}

AmountPaid.defaulProps = {
    dispatch: noop,
    className: '',
    faciltyData: EMPTY_OBJECT,
    sectionKey: '',
    paymentData: EMPTY_OBJECT,
    isInfo: false,
    setIsDisplayDetails: noop,
    hideTaxIncluded: false,
    isTokenApiStarts: false,
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(AmountPaid)