import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { downloadTicket, getPartnerDetailsBySLug, getTicketDetails } from '../../redux/actions/userDetails'
// import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
// import {formattedExpiry, getCardIcon, phoneMasker} from '../../utils/helper'
import { QRCodeSVG } from 'qrcode.react'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../global/Loader'
import NoData from '../../components/NoData'
import Footer from '../../components/global/Footer'
import MiniHeader from '../../components/global/MiniHeader'
import { errorMessage } from '../../constants/errorMessage'
import { getConfigDetails } from '../../services/config'
import { endParking } from '../../redux/actions/transientAction'
import { Circles } from 'react-loader-spinner'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
// import thankyou from '../../assets/img/thank-you-icon.svg'
import {
	formattedExpiry, getLocation, getTotalOverstayPaidAmount, isCheckoutButton, isExtendButton, isPermissionExist, isPromoValidateButton, loggedInUserData, phoneMasker, sortPortalPermissionByKey,
	// priceGreaterOrNot
} from '../../utils/helper'
import direction from '../../assets/img/traffic-sign.png'
import PaymentBreakUpDetails from '../../components/global/PaymentBreakUpDetails'

// import DownloadApp from '../../components/global/DownloadApp'
import PromptNumberUpdate from '../../global/PromptUpdateNumber'
import { getExtendList } from '../../redux/actions/ExtendFromAction'
import { getBreakUpDetails } from '../../utils/helper'
import URL from '../../constants/urls'

function Ticket(props) {
	const navigate = useNavigate()
	const { dispatch, ticketDetailsFromProps, loader, isFetching, notify, loaderDownload, isFetchingPartner } = props
	const [ticketDetails, setTicketDetails] = useState(EMPTY_OBJECT)
	const [error, setError] = useState('')
	const [isDisplayDetails, setIsDisplayDetails] = useState(true)

	const [isPromptOpen, setPromptNumber] = useState(false)
	const [isNumberUpdated, setIsNumberUpdated] = useState(false)
	const [isValidPartner, setIsValidPartner] = useState(false)
	const [overStay, setOverStay] = useState(EMPTY_ARRAY)
	const { ticketId, partner } = useParams()
	// const [remainingAmount, setRemainingAmount] = useState(null)
	const [partnerdetails, setPartnerDetails] = useState(EMPTY_OBJECT)
	const [loading, setLoading] = useState(true)
	const [accessPermissions, setAccessPermissions] = useState('')

	useEffect(() => {
		console.log(overStay)

	}, [EMPTY_ARRAY])

	useEffect(() => {
		setLoading(true)
		dispatch(getPartnerDetailsBySLug(partner)).then((res) => {
			setLoading(false)
			if (res?.data?.email) {
				const getAccessPermissionData = sortPortalPermissionByKey(res?.data, 'Restricted Permissions')
				setAccessPermissions(getAccessPermissionData)

				setPartnerDetails(res?.data)
				setIsValidPartner(true)
				localStorage.removeItem('partnerData')
				localStorage.setItem('partnerData', JSON.stringify(res?.data))
				const partnerData = res?.data
				let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
				let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
				rgb = JSON.parse(rgb)

				if (color) {
					document.documentElement.style.setProperty('--bs-primary', color)
					document.documentElement.style.setProperty('base-color', color)
					document.documentElement.style.setProperty('primary', color)
					document.documentElement.style.setProperty('textcolor', color)
					document.documentElement.style.setProperty('--bs-btn-bg', color)
					document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
					document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
					document.documentElement.style.setProperty('--bs-list-group-active-color', color)
					document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
				}

				if (ticketDetailsFromProps && ticketDetailsFromProps?.ticket_number != '') {
					setTicketDetails(ticketDetailsFromProps)
					// setRemainingAmount((Number(ticketDetailsFromProps?.payable_amount) - Number(ticketDetailsFromProps?.grand_total)).toFixed(2))
				} else {
					getTicketFunc(partnerData)
				}


			} else {
				setIsValidPartner(true)
				localStorage.removeItem('partnerData')
			}

		}).catch(() => {
			setLoading(false)
			console.log('error')

		})


	}, [EMPTY_ARRAY, ticketDetailsFromProps, isNumberUpdated])

	const getTicketFunc = (partnerData = null) => {
		if(!partnerData) {
			partnerData = JSON.parse(localStorage.getItem('partnerData'))
		}
		if (ticketId) {
			console.log()
			const payload = {
				'ticket': ticketId,
				'phone': loggedInUserData()?.user?.phone_number ? loggedInUserData()?.user?.phone_number : '',
				'cardLast4': '',
				'cardExpiry': '',
				'license_plate': '',
				'role': partnerData?.roles?.[0],
				'id': partnerData?.id,
			}
			setLoading(true)
			dispatch(getExtendList(ticketId)).then((ticketRes) => {
				const overStayDetails = getTotalOverstayPaidAmount(ticketRes?.data)
				setOverStay(overStayDetails)
			}).catch((ticketErr) => {
				console.log(ticketErr)
			})
			dispatch(getTicketDetails(payload)).then((res) => {
				setLoading(false)
				if (res?.status === 201 || res?.status === 200) {
					if (res?.data && res?.data != null) {
						// notify('success', 'T successfully')

						// for the prompt open for number 
						if ((!res?.data?.user?.phone && !res?.data?.user?.phone_number) || (!res?.data?.user?.phone > 9 && !res?.data?.user?.phone_number > 9)) {
							setPromptNumber(true)
						}
						if (res?.data?.facility?.facilty_brand_setting?.color) {
							const color = res?.data?.facility?.facilty_brand_setting?.color
							const rgb = JSON.parse(res?.data?.facility?.facilty_brand_setting?.rgb_color)
							if (color) {
								document.documentElement.style.setProperty('--bs-primary', color)
								document.documentElement.style.setProperty('base-color', color)
								document.documentElement.style.setProperty('primary', color)
								document.documentElement.style.setProperty('textcolor', color)
								document.documentElement.style.setProperty('--bs-btn-bg', color)
								document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
								document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
								document.documentElement.style.setProperty('--bs-list-group-active-color', color)
								document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
							}
						}
						//
						// setRemainingAmount((Number(res?.data?.payable_amount) - Number(res?.data?.grand_total)).toFixed(2))
						localStorage.setItem('facilityBrandSettings', JSON.stringify(res?.data?.facility?.facilty_brand_setting))
						localStorage.setItem('userPhone', res?.data?.user?.phone ? res?.data?.user?.phone : '')
						localStorage.setItem('user_Phone_number', res?.data?.user?.phone_number ? res?.data?.user?.phone_number : '')
						setTicketDetails(res?.data)

					} else {
						setLoading(false)
						notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
						setPromptNumber(false)
					}
				} else {
					setLoading(false)
					setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
					// notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
					setPromptNumber(false)
				}
			}).catch(() => {
				setLoading(false)
				notify('error', errorMessage?.somethingWentWrong)
				setPromptNumber(false)
			})
		} else {
			setLoading(false)
			setTicketDetails('')
			setPromptNumber(false)
		}
	}

	const handleEndParking = () => {
		dispatch(endParking(ticketDetails?.ticket_number)).then((res) => {
			if (res?.status === 201 || res?.status === 200) {
				if (res?.data && res?.data != null) {
					// notify('success', 'T successfully')

					setTicketDetails(res?.data)
					getTicketFunc()
				} else {
					notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
				}
			} else {
				setError(res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
				notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
			}
		}).catch(() => {
			notify('error', errorMessage?.somethingWentWrong)
		})
	}

	const handleDownloadTicket = () => {
		dispatch(downloadTicket(ticketId)).then((res) => {
			if (res?.status && res?.status == 500) {
				notify('error', errorMessage?.somethingWentWrong)
			}
			notify('Success', 'E-Ticket Downloaded')

		}).catch(() => {
			notify('error', errorMessage?.somethingWentWrong)
		})
	}

	const handlePromptset = () => {
		setPromptNumber(!isPromptOpen)
	}


	const handleValidateTicket = (paymentData) => {
		if (paymentData?.paid_date) {
			notify('error', 'This ticket is already validated.')
		} else {
			navigate(`/${partner}${URL?.SCAN_VALIDATE}/${paymentData?.ticket_number}`)
		}
	}

	// const handleDetailsDisplay = () => {
	// 	setIsDisplayDetails(true)
	// }

	return (
		isFetchingPartner || loading ? <Loader /> : <div className={ticketId ? 'main' : ''}>
			{ticketId && <MiniHeader partnerdetails={partnerdetails} title={loader ? '' : ticketDetails?.is_expired == 0 ? 'e-Ticket' : 'e-Receipt'} handleDownload={handleDownloadTicket} download facilityData={ticketDetails} />}
			{(isValidPartner) ? loader ? <Loader /> :
				(ticketDetails?.ticket_number && ticketDetails?.ticket_number != '') ?
					<div className={`${ticketId ? 'm-2 ' : ''}card shadow-lg bg-white rounded border-0 ticket-font`}>
						{/* <div className="card-header headerBg row p-1 m-0"> */}
						{/* <div className='col-12 d-flex- justify-content-center align-items-center text-center'>
							<img className='img-50 img-height-50 p-2' src={ticketDetails?.facility?.logo} alt={ticketDetails?.facility?.full_name}/> */}
						{/* <p className="pull-left my-2 add-member-plan text-primary fw-bold small mb-0 pb-0">{ticketDetails?.facility?.full_name}</p>
							<p className="pull-left my-2 add-member-plan small mt-0 pt-0">{ticketDetails?.facility?.entrance_location}</p> */}
						{/* </div> */}
						{/* <div>
						<a href={getLocation(ticketDetails?.facility)} target="_blank" rel="noreferrer"><img src={direction} alt="Direction" className='direction'/></a>
						</div> */}
						{/* <div className='col-4 d-flex align-items-center justify-content-center small'>
						{ticketDetails?.facility?.garage_code && <p className="my-2 header-s rounded-pill bg-dark ps-3 pe-3 text-white d-flex pt-1 pb-1 small h5 me-0 pe-0">CODE: {ticketDetails?.facility?.garage_code}</p>}
						</div> */}

						{/* </div> */}
						<div className="card-body m-0 p-0 pb-5">
							<div className="clearfix example ">

								{/* THANK YOU */}
								<div className=' text-center pt-2 pb-3'>
									{/* <img src={thankyou} className='imgh-20 thankyou-img-height-80 mt-2' alt='Thank You'/> */}
									<h6 className='mt-3 fw-bold mb-3 ticket-font'>Thank You for Parking!</h6>
									{/* <p className='ms-2 me-2 text-black mt-2 pb-0 mb-0 ticket-font'>For parking with {ticketDetails?.facility?.full_name}</p> */}
								</div>
								<div className='row m-2 border border-primary pe-0'>
									{ticketDetails?.ticket_number && (
										<div className='col-3 m-0 p-0 ms-2 mt-2 mb-2 responsive-canvas'>
											<QRCodeSVG
												value={
													ticketDetails?.facility?.facility_configuration?.is_sbm_event_qrcode == 1 ? 
													ticketDetails?.ticket_security_code :
													ticketDetails?.reservation?.ticketech_code ? 
													ticketDetails.reservation.ticketech_code : 
													`${getConfigDetails()?.adminUrl}/admin/tickets?ticket_number=${ticketDetails?.ticket_number}`}
												width="100%"
												height="100%"
											// size={180}
											/>
										</div>)}
									<div className='col-9 col-auto row  me-0 pe-0'>
										<div className='col-12 ps-2 mt-3 mb-2 me-0 pe-0'>
											{ticketDetails?.ticket_number && <span className='text-start text-primary small pb-0 mb-0'>Ticket ID: {ticketDetails?.ticket_number}</span>}
											{isPermissionExist(accessPermissions, 'Hide Facility') ? null : <a href={getLocation(ticketDetails?.facility)} target="_blank" rel="noreferrer" className='float-end me-1'><img src={direction} alt="Direction" className='direction' /></a>}
											<h6 className='text-start text-primary mt-2 mb-0 fw-bold small'>{ticketDetails?.facility?.full_name} {ticketDetails?.is_checkout == 0 ? ticketDetails?.checkin_gate_name ? `(${ticketDetails?.checkin_gate_name})` : '' : ticketDetails?.checkout_gate_name ? `(${ticketDetails?.checkout_gate_name})` : ''}</h6>
											<p className='text-start text-black pb-0 mb-0 small'>Entrance: {ticketDetails?.facility?.entrance_location ? `${ticketDetails?.facility?.entrance_location}` : ''} </p>
											{(ticketDetails?.paid_date) && <p className='text-start text-black pb-0 mb-0 small'>Status: <span className='p-0 m-0 ms-3 ticket-font text-success'><i className="fa fa-check" aria-hidden="true"></i> Validated</span> </p>}
										</div>
										{/* <div className='col-4 d-flex align-items-center justify-content-end'>
									</div> */}
									</div>
								</div>
							</div>
							<div className='m-2 mt-5'>
								<h6>Details</h6>
							</div>
							<div className=' mt-0 m-2 small'>
								<div className='row m-0 bg-white d-flex align-items-center px-2 pt-2'>

									<div className='col-sm-12 col-sm-8 p-0 m-0'>
										{ticketDetails?.user?.name && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Name</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.user?.name ? ticketDetails?.user?.name : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.user?.email && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Email Id</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.user?.email ? ticketDetails?.user?.email : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.user?.phone_number && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Phone No</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.user?.phone_number ? phoneMasker(ticketDetails?.user?.phone_number) : '-'}</p>
											</div>
										</div>}
										{(ticketDetails?.reservation && ticketDetails?.reservation?.ticketech_code) && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Booking ID</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.reservation?.ticketech_code ? ticketDetails?.reservation?.ticketech_code : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.license_plate && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>License Plate</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.license_plate ? ticketDetails?.license_plate : '-'}</p>
											</div>
										</div>}
										{/* {ticketDetails?.ticket_number && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Ticket ID</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.ticket_number ? `${ticketDetails?.ticket_number}` : '-'}</p>
											</div>
										</div>} */}
										{/* {ticketDetails?.checkin_gate && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-In Gate</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.checkin_gate ? `${ticketDetails?.checkin_gate}` : '-'}</p>
											</div>
										</div>}
										{ticketDetails?.checkout_gate && <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-Out Gate</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className='p-0 m-0 ticket-font'>{ticketDetails?.checkout_gate ? `${ticketDetails?.checkout_gate}` : '-'}</p>
											</div>
										</div>} */}
										{ticketDetails?.checkin_time ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-In Time</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												{/* <p >{ticketDetails?.checkin_time ? moment(ticketDetails?.checkin_time).format('Do MMM YYYY h:mmA') : '-'}</p> */}
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.checkin_time ? moment(ticketDetails?.checkin_time).format('MM/DD/YYYY hh:mm A') : '-'}</p>
											</div>
										</div> : null}
										{(ticketDetails?.checkout_time && ticketDetails?.is_checkout == '1') ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-Out Time</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												{/* <p >{ticketDetails?.checkin_time ? moment(ticketDetails?.checkin_time).format('Do MMM YYYY h:mmA') : '-'}</p> */}
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.checkout_time ? moment(ticketDetails?.checkout_time).format('MM/DD/YYYY hh:mm A') : '-'}</p>
											</div>
										</div> : null}
										{(ticketDetails?.card_type || ticketDetails?.transaction?.card_type) ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Card Type</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.card_type ? ticketDetails?.card_type : ticketDetails?.transaction?.card_type ? ticketDetails?.transaction?.card_type : '-'}</p>
											</div>
										</div> : null}
										{(ticketDetails?.card_last_four || ticketDetails?.transaction?.payment_last_four) ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Payment Method</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.card_last_four ? `Ending with ${ticketDetails?.card_last_four}` : ticketDetails?.transaction?.payment_last_four ? `Ending with ${ticketDetails?.transaction?.payment_last_four}` : '-'}</p>
											</div>
										</div> : null}
										{(ticketDetails?.expiry || ticketDetails?.transaction?.expiration) ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Card Expiry</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.expiry ? formattedExpiry(ticketDetails?.expiry) : ticketDetails?.transaction?.expiration ? formattedExpiry(ticketDetails?.transaction?.expiration) : '-'}</p>
											</div>
										</div> : null}
										{(ticketDetails?.lot_facility && ticketDetails?.lot_facility != '' && ticketDetails?.lot_facility?.id != ticketDetails?.facility?.id) ? <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Check-In Facility</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0  ticket-font'>{ticketDetails?.lot_facility?.full_name ? ticketDetails?.lot_facility?.full_name : '-'}</p>
											</div>
										</div> : null}

										{/* {(ticketDetails?.amount_paid && Number(ticketDetails?.payable_amount) == 0) ? 
										<div className='col-12 m-auto border-bottom row d-flex align-items-center'>
											<div className='col-5 small p-0 m-0 pt-2 pb-2'>
												<p className='fw-bold p-0 m-0 ticket-font'>Amount Paid</p>
											</div>
											<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
												<p className=' p-0 m-0 ticket-font'>{ticketDetails?.amount_paid ? ((ticketDetails?.reservation) ?  overStay ? overStay : 'Prepaid' : `$${Number(ticketDetails?.amount_paid).toFixed(2)}`) : '-'}
												{(priceGreaterOrNot(ticketDetails) || ticketDetails?.is_overstay == '1') && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>}
												</p>
											</div>
										</div> 
										: null
										} */}
										{(
											ticketDetails?.payable_amount != null
											// && Number(ticketDetails?.payable_amount) > 0
											// Above commented due to the Info icon issue of Price breakup
										) ? <>
											{/* <div className='col-12 m-auto border-bottom row d-flex align-items-center'>
												<div className='col-5 small p-0 m-0 pt-2 pb-2'>
													<p className='fw-bold p-0 m-0 ticket-font'>Amount To Pay</p>
												</div>
												<div className='col-7 small p-0 m-0 pt-2 pb-2 ps-2 '>
													<p className=' p-0 m-0 ticket-font'>${ticketDetails?.payable_amount ? Number(ticketDetails?.payable_amount).toFixed(2) : '-'} 
														{(priceGreaterOrNot(ticketDetails) || ticketDetails?.is_overstay == '1') && <i className='fa fa-info-circle text-primary ms-1 cursor-pointer' onClick={() => handleDetailsDisplay()}></i>}
													</p>
												</div>
											</div> */}
											<div className='mt-3'>
												<div className="container pt-3 pb-5 overflow-hidden">
													<div className={getBreakUpDetails(ticketDetails)?.length ? 'card shadow rounded-lg receiptCard' : 'card shadow rounded-lg receiptCard no-view'}>
														<PaymentBreakUpDetails
															className="d-flex justify-content-between"
															paymentData={ticketDetails}
															sectionKey={(ticketDetails?.payable_amount != '0.00' || Number(ticketDetails?.payable_amount) > 0) ? '' : 'paymantResponse'}
															isDisplayDetails={isDisplayDetails}
															setIsDisplayDetails={setIsDisplayDetails}
															isExtendDetails
														/>
													</div>
												</div>
											</div>
										</> : null}
										{/* <PaymentBreakUpDetails
											className="my-2 d-flex justify-content-between"
											paymentData={ticketDetails}
											sectionKey={(ticketDetails?.payable_amount != '0.00') ? false : 'paymantResponse'}
											isDisplayDetails={true}
											setIsDisplayDetails={setIsDisplayDetails}
											isExtendDetails
										/> */}
										
										{isCheckoutButton(ticketDetails?.facility, ['1']) && (ticketDetails?.is_checkout == 0 && ticketDetails?.facility?.is_gated_facility != 0) && <div className='col-12 m-auto border border-primary rounded mt-2 bg-primary row d-flex align-items-center'>
											
										<a
											href={`${getConfigDetails()?.checkInUrl}/${partner}/${ticketDetails?.encrypt_ticket_number}/${loggedInUserData()?.access_token ? loggedInUserData()?.access_token : ''}`}
											type='button'
											className='btn text-decoration-none border-primary rounded'
										><div className='text-center bg-primary text-white text-decoration-none'>
												<p className='p-0 m-0 ticket-font fw-bold text-center'>Click to Check-Out</p>
											</div></a>
										</div>}
										{((ticketDetails?.is_expired == '0') && (ticketDetails?.payable_amount && Number(ticketDetails?.payable_amount) > 0) && isPromoValidateButton(ticketDetails, ['2', '3']) && !ticketDetails?.paid_date && !ticketDetails?.reservation_id) ? <div className='mt-3 mb-2'>
											<div className='col-sm-12 small'>
												<button
													type="button"
													className='btn border-primary border-btn d-block w-100'
													onClick={() => handleValidateTicket(ticketDetails)}
												>
													Validate Ticket
												</button>
											</div>
										</div> : null}
										{(ticketDetails?.facility?.is_gated_facility != '1' && ticketDetails?.is_expired == '0') && <div className='mt-3 mb-5'>
											{(isExtendButton(ticketDetails?.facility, ['1']) && isValidPartner && !ticketDetails?.event_id && !ticketDetails?.permit_request_id && !ticketDetails?.pass_id) && <div className='col-sm-12 small'>
												<a
													href={`${getConfigDetails()?.transientURL}/${partner}/extend/${ticketDetails?.ticket_number}`}
													type='button'
													className='btn border-primary border-btn d-block w-100'>
													<i className='fa fa-plus-circle'></i> Extend
												</a>
												{/* className='text-decoration-none w-100 border bg-white text-center text-primary border-primary rounded ps-4 pe-4 fw-bold pt-2 pb-2' */}
											</div>}
											{/* Hide This */}
											{ticketDetails?.facility?.is_end_parking == '1' && <div className='col-sm-6 small mt-3 mb-5'>
												<button
													type='button'
													className='btn text-decoration-none border w-100 bg-white text-center text-primary border-primary border-btn rounded '
													onClick={() => handleEndParking()}>
													<div className='d-flex justify-content-center align-items-center'>
														<span>End Current Parking</span>
														<span className='ms-2'>
															{isFetching ? <Circles
																height="20"
																width="20"
																style={{ display: 'inline-block' }}
																color="#27A8E1"
																ariaLabel="circles-loading"
																visible={true}
															/> : null}
														</span>
													</div>
												</button>
											</div>}
										</div>}

									</div>
								</div>
							</div>
							{(ticketDetails?.refund_status == 'Canceled' || ticketDetails?.refund_status == 'Refunded') ? <div className='px-3'>
								The Ticket is already canceled and refunded so no further action can be taken
							</div> : ''}
						</div>
						{/* <div className="card-footer row p-2 m-0 bg-primary">
						<div className='col-7 d-flex align-items-center justify-content-left'>
							<p className=" text-right my-2 text-white fw-bold">Paid Amount</p>
						</div>
						<div className='col-5 d-flex align-items-center justify-content-end'>
							<p className="my-2 ps-3 pe-3 text-white d-flex pt-1 pb-1 small h5">{ti	cketDetails?.grand_total ? `$${ticketDetails?.grand_total}` : 'NA'}</p>
						</div>
						
					</div> */}
					</div> : error && <NoData message={error} />
				: <NoData message={errorMessage?.invalidPartner} />}
			{ticketId && <Footer />}
			{/* <DownloadApp/> */}
			{loaderDownload && <div className='top-0 justify-content-center position-fixed w-100 h-100 zIndexHigh bg-black d-flex align-items-center opacity text-center opacity-25 text-white'>
				<i className='fa fa-spinner fa-spin'></i>
			</div>}

			<PromptNumberUpdate
				isPromptModalOpen={isPromptOpen}
				setPromptNumber={setPromptNumber}
				setIsPromptModal={handlePromptset}
				Message='Please link you phone number'
				isInputShow
				ticketDetails={ticketDetails}
				setTicketDetails={setTicketDetails}
				notify={notify}
				setIsNumberUpdated={setIsNumberUpdated}
			/>
		</div>

	)
}


Ticket.propTypes = {
	dispatch: PropTypes.func,
	loader: PropTypes.bool,
	loaderSms: PropTypes.bool,
	loaderDownload: PropTypes.bool,
	isFetching: PropTypes.bool,
	ticketDetailsFromProps: PropTypes.object,
	notify: PropTypes.func,
	isFetchingPartner: PropTypes.bool,
}

Ticket.defaulProps = {
	dispatch: noop,
	loader: false,
	isFetching: false,
	ticketDetailsFromProps: {},
	notify: noop,
	isFetchingPartner: true,
}

function mapStateToProps({ userDetailsReducer, transientReducer }) {
	return {
		loader: userDetailsReducer?.loader,
		loaderSms: userDetailsReducer?.loaderSms,
		loaderDownload: userDetailsReducer?.loaderDownload,
		isFetching: transientReducer?.isFetching,
		isFetchingPartner: userDetailsReducer?.isFetching,

	}
}
export default connect(mapStateToProps)(Ticket)

