import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import URL from '../constants/urls'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import Main from '../pages/main/Main'
import Ticket from '../pages/main/Ticket'
import SearchTicket from '../pages/searchTicket/SearchTicket'
import NotFound from '../components/global/NotFound'
import { toast, ToastContainer } from 'react-toastify'
import Singup from '../components/global/login/singup'
import Login from '../components/global/login/login'
import SendEmailResetPassword from '../components/global/SendEmailResetPassword'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword'
import useNetwork from '../Hooks/NetworkDetector'
import { getPartnerDetailsBySLug } from '../redux/actions/userDetails'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../constants/globalConstants'
import { isPermissionExist, sortPortalPermissionByKey } from '../utils/helper'
import Loader from '../global/Loader'
import LoadingScreen from '../components/global/LoadingScreen'
import ScanAutoValidationQr from '../components/global/ScanAutoValidationQr'

function PublicRoutes(props) {
    const { dispatch } = props

    const [faviconPath, setFaviconPath] = useState('')
    const [title, setTitle] = useState('')
    const [loading, setLoading] = useState(true)
    const user = window.location.toString().split('/')[3]
    const [partnerDetails, setPartnerDetails] = useState(EMPTY_OBJECT)
    const facility = window.location.toString().split('/')[4]
    const isOnline = useNetwork()
    const [isPortalPermissionAvailable, setIsPortalPermissionAvailable] = useState(false)


    useEffect(() => {
        const updateFavicon = () => {
            const favicon = document.querySelector('link[rel="icon"]')
            favicon.href = faviconPath
        }

        const updateTitle = () => {
            document.title = title
        }

        updateFavicon()
        updateTitle()
    }, [faviconPath, title])

    useEffect(() => {
        if (user) {
            let slug = user
            if (facility && facility != 'ticket' && facility != 'login' && facility != 'singup') {
                slug = `${slug}/${facility}`
            }
            setLoading(true)
            localStorage.removeItem('partnerData')

            dispatch(getPartnerDetailsBySLug(slug)).then((res) => {
                setLoading(false)
                if (res?.data?.email) {
                    setTitle(res?.data?.company_name ? res?.data?.company_name : res?.data?.name)
                    setFaviconPath(res?.data?.brand_setting ? (res?.data?.brand_setting?.favicon && res?.data?.brand_setting?.faviconurl) ? res?.data?.brand_setting?.faviconurl : res?.data?.brand_setting?.logourl ? res?.data?.brand_setting?.logourl : res?.data?.partner_details?.brand_setting?.logourl : res?.data?.partner_details?.brand_setting?.logourl)
                    localStorage.removeItem('partnerData')
                    if (res?.data?.customer_portal_permission && Object.keys(res?.data?.customer_portal_permission)?.length > 0) {
                        setPartnerDetails(res?.data)
                        const perData = sortPortalPermissionByKey(res?.data, 'Access Permissions')
                        if (!isPermissionExist(perData, 'Receipt Login Access')) {
                            localStorage.removeItem('sign_in')
                            localStorage.removeItem('sign_up')
                            localStorage.setItem('isLoggedin', false)
                        }
                    }
                    localStorage.setItem('partnerData', JSON.stringify(res?.data))
                    const partnerData = res?.data
                    let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
                    let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
                    rgb = JSON.parse(rgb)
                    if (color) {
                        document.documentElement.style.setProperty('--bs-primary', color)
                        document.documentElement.style.setProperty('base-color', color)
                        document.documentElement.style.setProperty('primary', color)
                        document.documentElement.style.setProperty('textcolor', color)
                        document.documentElement.style.setProperty('--bs-btn-bg', color)
                        document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
                        document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
                        document.documentElement.style.setProperty('--bs-list-group-active-color', color)
                        document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
                    }

                } else {
                    console.log('error')
                }

            }).catch(() => {
                setLoading(false)
                console.log('error')

            })
        } else {
            setLoading(false)
        }

    }, [EMPTY_ARRAY])


    const notify = (type, message) => {
        if (type === 'error') {
            toast.error(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        } else if (type === 'success') {
            toast.success(message, {
                position: toast.POSITION.TOP_RIGHT
            })
            toast.clearWaitingQueue()
        }
    }

    // if(user == 'townsend'){
    //     document.documentElement.style.setProperty('--bs-primary','#202D5D')
    //     document.documentElement.style.setProperty('base-color','#202D5D')
    //     document.documentElement.style.setProperty('primary','#202D5D')
    //     document.documentElement.style.setProperty('textcolor','#202D5D')
    //     document.documentElement.style.setProperty('--bs-btn-bg','#202D5D')
    //     document.documentElement.style.setProperty('--bs-primary-rgb', '34, 32, 93')
    //     document.documentElement.style.setProperty('--bs-list-group-active-border-color','#202D5D')
    //     document.documentElement.style.setProperty('--bs-list-group-active-color','#202D5D')
    //     document.documentElement.style.setProperty('--bs-list-group-active-bg','#202D5D')
    // }else if(user == 'intrapark'){
    //     document.documentElement.style.setProperty('--bs-primary','#202D5D')
    //     document.documentElement.style.setProperty('base-color','#202D5D')
    //     document.documentElement.style.setProperty('primary','#202D5D')
    //     document.documentElement.style.setProperty('textcolor','#202D5D')
    //     document.documentElement.style.setProperty('--bs-btn-bg','#202D5D')
    //     document.documentElement.style.setProperty('--bs-primary-rgb', '34, 32, 93')
    //     document.documentElement.style.setProperty('--bs-list-group-active-border-color','#202D5D')
    //     document.documentElement.style.setProperty('--bs-list-group-active-color','#202D5D')
    //     document.documentElement.style.setProperty('--bs-list-group-active-bg','#202D5D')
    // }

    const DisplayToast = (text, title) => {
        return <div className='network-warning'>
            <span className='text-center fw-bold d-block'>{title}</span>
            <span className='text-center'>{text}</span>
        </div>
    }

    useEffect(() => {
        const hasPortalAccess = isPermissionExist(sortPortalPermissionByKey(partnerDetails, 'Access Permissions'), 'e-Receipt Portal Access')
        setIsPortalPermissionAvailable(hasPortalAccess)
    }, [EMPTY_ARRAY, partnerDetails])

    return (
        <div>
            {loading ? <Loader /> :
                <BrowserRouter forceRefresh className="mt-3">
                    {!isOnline ?
                        DisplayToast('Please check the Internet Connection!', 'Warning') : null}
                    {isPortalPermissionAvailable ? <Routes>
                        <Route
                            path={URL.HOME}
                            element={
                                <Main notify={notify} />
                            }
                        />
                        <Route
                            path={URL.HOME2}
                            element={
                                <Main notify={notify} />
                            }
                        />
                        <Route
                            path={URL.INDEX}
                            element={
                                <Main notify={notify} />
                            }
                        />
                        {/* <Route
                        path={URL.HOME2}
                        element={
                            <Transient />
                        }
                    /> */}
                        {/* <Route
                        path={URL.USER_PROFILE}
                        element={
                            <UserProfile />
                        }
                    /> */}
                        {/* <Route
                        path={URL.EXTEND_SUCCESS}
                        element={
                            <ExtendResponse />
                        }
                    />
                    <Route
                        path={URL.CONFIRMATION_SCREEN}
                        element={
                            <PaymentResponse />
                        }
                    /> 
                    <Route
                        path={URL.DETAILS}
                        element={
                            <PaymentResponse />
                        }
                    />  */}
                        {/* <Route
                        path={URL.TICKET_EXTEND}
                        element={
                            <ExtendFormNew />
                        } /> */}
                        <Route
                            path={URL.TICKET}
                            element={
                                <Ticket notify={notify} partnerDetails={partnerDetails} />
                            }
                        />
                        <Route
                            path={URL.SCAN_TOKEN_VALIDATE}
                            element={
                                <ScanAutoValidationQr notify={notify} partnerDetails={partnerDetails} />
                            }
                        />
                        <Route
                            path={URL.SEARCH}
                            element={
                                <SearchTicket notify={notify} />
                            }
                        />
                        {/* login routes  */}
                        <Route
                            path={URL.LOGIN_INDEX}
                            element={<Login notify={notify} partnerDetails={partnerDetails} />}
                        />
                        <Route
                            path={URL.SING_UP}
                            element={<Singup notify={notify} partnerDetails={partnerDetails} />}
                        />
                        <Route
                            path={URL.FORFOT_PASSWORD}
                            element={
                                <ForgotPassword notify={notify} />
                            }
                        />
                        <Route
                            path={URL.SEND_EMAIL_RESET_PASSWORD}
                            element={
                                <SendEmailResetPassword notify={notify} />
                            }
                        />
                        <Route
                            path={URL.LOGIN_INDEX_FACILITY}
                            element={<Login notify={notify} partnerDetails={partnerDetails} />}
                        />
                        <Route
                            path={URL.SING_UP_FACILITY}
                            element={<Singup notify={notify} partnerDetails={partnerDetails} />}
                        />
                        <Route
                            path={URL.FORFOT_PASSWORD_FACILITY}
                            element={
                                <ForgotPassword notify={notify} />
                            }
                        />
                        <Route
                            path={URL.SEND_EMAIL_RESET_PASSWORD_FACILITY}
                            element={
                                <SendEmailResetPassword notify={notify} />
                            }
                        />
                        <Route
                            path={URL.INVALID}
                            element={
                                <NotFound notify={notify} />
                            }
                        />
                    </Routes> : <Routes>

                        <Route
                            path={URL.HOME2}
                            element={
                                <LoadingScreen notify={notify} />
                            }
                        />

                        {/* <Route
                            path={URL.TICKET}
                            element={
                                <LoadingScreen notify={notify} />
                            }
                        /> */}
                        <Route
                            path={URL.SEARCH}
                            element={
                                <LoadingScreen notify={notify} />
                            }
                        />
                        <Route path={URL.INVALID} element={<NotFound />} />
                    </Routes>}
                </BrowserRouter>}
            <ToastContainer limit={1} />
        </div>
    )
}

PublicRoutes.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    cards: PropTypes?.array,
    notify: PropTypes?.func,
    isUserLogin: PropTypes?.bool,
}

PublicRoutes.defaulProps = {
    dispatch: noop,
    loader: true,
    isFetching: true,
    cards: EMPTY_ARRAY,
    notify: noop,
    isUserLogin: false,
}

function mapStateToProps({ gatedCheckinReducer, PaymentReducer, signInSignUpReducer, userDetailsReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        cards: PaymentReducer?.data?.data?.payments,
        isUserLogin: signInSignUpReducer?.isUserLogin,
        isFetching: userDetailsReducer?.loader,

    }
}
export default connect(mapStateToProps)(PublicRoutes)