import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { errorMessage } from '../../constants/errorMessage'
import { ticketValidation } from '../../redux/actions/ticketValidationAction'
import { useNavigate, useParams } from 'react-router-dom'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import CommonQr from '../../global/ScanQrCode/CommonQr'

function ScanAutoValidationQr(props) {

    const navigate = useNavigate()
    const [isDataRecorded, setIsDataRecorded] = useState(false)
    const { dispatch, notify, partnerDetails } = props
    const { partner, ticket_id } = useParams()

    const handleScan = (data) => {
        let qrcodeId = data?.text?.split('/').pop()
        if (data?.text) {
            if (!isDataRecorded) {
                setIsDataRecorded(true)
                if (qrcodeId) {
                    apifuntocall(qrcodeId, ticket_id)
                } else {
                    notify('error', 'Invalid QR Code, Please scan a valid QR!')
                    navigate(`/${partner}/ticket/${ticket_id}`)
                }
                setIsDataRecorded(true)
            }
        } else {
            setIsDataRecorded(false)
        }
    }
    const doNothing = () => { }
    const handleError = () => {
        setIsDataRecorded(true)
    }

    const apifuntocall = (qrcodeId, ticketNumber) => {
        setIsDataRecorded(true)
        const payload = { 'ticket_number': ticketNumber, channel: 'web', business_qrcode: qrcodeId }
        dispatch(ticketValidation(payload)).then((res) => {
            if (res?.status === 201 || res?.status === 200) {
                navigate(`/${partner}/ticket/${ticketNumber}`)
                notify('success', 'E-Ticket has been validated successfully.')
            } else {
                notify(
                    'error',
                    res?.errors?.message
                        ? res?.errors?.message
                        : errorMessage?.somethingWentWrong
                )
                navigate(`/${partner}/ticket/${ticketNumber}`)
            }
        })
    }

    return (
        <div>
            <CommonQr
                isDisplayBack={true}
                isHeader={true}
                noRightIcon={true}
                noBrandIcon={true}
                title="Ticket Validation"
                subTitle="Please Scan the Business QR Code to validate Ticket."
                isParentCss={false}
                partnerDetails={partnerDetails}
                onError={handleError}
                onScan={!isDataRecorded ? handleScan : doNothing}
            />
        </div>
    )
}

ScanAutoValidationQr.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    notify: PropTypes.func,
    partnerDetails: PropTypes.object
}

ScanAutoValidationQr.defaulProps = {
    dispatch: noop,
    loader: false,
    isFetching: false,
    notify: noop,
    partnerDetails: EMPTY_OBJECT
}

function mapStateToProps({ gatedCheckinReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        isFetching: gatedCheckinReducer?.isFetching
    }
}
export default connect(mapStateToProps)(ScanAutoValidationQr)
