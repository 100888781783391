import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '../constants/globalConstants'
import { Controller, useForm } from 'react-hook-form'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { updatePhoneNumber } from '../redux/actions/PromptAction'
import { Circles } from 'react-loader-spinner'
import { errorMessage } from '../constants/errorMessage'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999')

function PromptNumberUpdate(props) {
    const { handleSubmit, formState: { errors }, control } = useForm()
    const { isPromptModalOpen, setIsPromptModal, ticketDetails, dispatch, loader, notify, setTicketDetails, setPromptNumber, setIsNumberUpdated } = props

    const onSubmit = (data) => {
        const payload = { ...data, 'ticket_number': ticketDetails?.ticket_number }
        if (payload?.phone && payload?.ticket_number) {
            if (!loader) {
                dispatch(updatePhoneNumber(payload)).then((res) => {
                    if (res?.data && res?.data != null && res?.data != undefined) {
                        notify('success', 'Phone number updated successfully !')
                        setTicketDetails(res?.data)
                        setIsPromptModal(false)
                        setIsNumberUpdated(true)
                    } else {
                        notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                        setIsPromptModal(false)
                        setIsNumberUpdated(false)

                    }
                }).catch((err) => {
                    notify('error', err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)
                    setIsPromptModal(false)
                    setIsNumberUpdated(false)

                })
            }
        }
    }

    const handleClosePop = () => {
        setPromptNumber(false)
    }

    return (
        <>
            <Modal show={isPromptModalOpen} onHide={() => setIsPromptModal()} centered backdrop="static" keyboard={false}>
                <Modal.Body className='messageCss d-flex justify-content-between'>{errorMessage?.linkPhoneMessage}<i className='fa fa-close text-end' onClick={() => handleClosePop()}></i></Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)} className='mb-3' style={{ marginTop: '-1.5rem' }} >
                    <Modal.Body>
                        <Form.Group>
                            {/* <Form.Label className='text-primary labelstylePropmpt'>Phone Number</Form.Label> */}
                            <Controller
                                name="phone"
                                type="text"
                                id="phone"
                                control={control}
                                defaultValue=''
                                rules={{required:{ value: true, message: 'Please enter a valid phone number' }, 
                                        minLength: { value: 10, message: 'Please enter a valid phone number' }, 
                                        maxLength: { value: 10, message: 'Please enter a valid phone number' } 
                                }}
                                render={({ field }) => <MaskedInput
                                    maskGenerator={phoneMaskGenerator}
                                    className="form-control"
                                    {...field}
                                    id="phone"
                                    maxLength={14}
                                    inputMode="numeric"
                                    title="Please enter a valid phone number"
                                    onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    placeholder="(123)-555-1234"
                                />
                                }
                            />
                        </Form.Group>
                        {errors?.phone && <Form.Text className="text-danger">{errors?.phone?.message}</Form.Text>}
                    </Modal.Body>
                    <Modal.Footer className='confirationModalfooter py-0'>
                        <Button variant={' text-light'} className='bg-primary' type="submit" name="submit" size="sm" onClick={() => onSubmit()} disabled={(loader) ? true : false}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <span>Update</span>
                                <span className='ms-2'>
                                    {(loader) ? <Circles
                                        height="20"
                                        width="20"
                                        style={{ display: 'inline-block' }}
                                        color="white"
                                        ariaLabel="circles-loading"
                                        visible={true}
                                    /> : null}
                                </span>
                            </div>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

PromptNumberUpdate.propTypes = {
    dispatch: PropTypes.func,
    isPromptModalOpen: PropTypes.bool,
    setIsPromptModal: PropTypes.func,
    isInputShow: PropTypes.bool,
    ticketDetails: PropTypes.object,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    setTicketDetails: PropTypes.func, 
    setPromptNumber: PropTypes.func,
    setIsNumberUpdated: PropTypes.func,
}

PromptNumberUpdate.defaulProps = {
    dispatch: noop,
    isPromptModalOpen: false,
    setIsPromptModal: noop,
    isInputShow: false,
    ticketDetails: EMPTY_OBJECT,
    loader: false,
    notify: noop,
    setTicketDetails: noop,
    setPromptNumber: noop,
    setIsNumberUpdated: noop,
}

function mapStateToProps({ promptReducer }) {
    return {
        loader: promptReducer?.loader
    }
}

export default connect(mapStateToProps)(PromptNumberUpdate)