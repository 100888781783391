import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPartnerDetails, getPartnerLogo, isPermissionExist, loggedInUserData, sortPortalPermissionByKey } from '../../utils/helper'
import { getConfigDetails } from '../../services/config'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

function MiniHeader(props) {

    const { isDisplayBack, title, download, handleDownload, className, partnerdetails, noRightIcon, noBrandIcon } = props
    const getAccessPermissionData = sortPortalPermissionByKey(partnerdetails, 'Access Permissions')
    const partner = window.location.toString().split('/')[3]
    const [menuDisplay, setMenuDisplay] = useState(false)
    const navigate = useNavigate()
    const handleBackClick = () => {
        navigate(-1)
    }

    const handleLogin = () => {
        localStorage.setItem('loginHistory', location?.pathname)

        // if(facilityData && facilityData?.facility?.short_name){
        //     navigate(`/${partner}/${facilityData?.facility?.short_name}/login`)
        // } else {
        navigate(`/${partner}/login`)
        // }
    }
    const handleMenu = () => {
        setMenuDisplay(!menuDisplay)
    }

    const handleMyAccountView = () => {
        setMenuDisplay(false)
        window.open(`${getConfigDetails()?.portalURL}/${loggedInUserData()?.access_token}/${loggedInUserData()?.refresh_token}/1/${partner}`, '_self')
    }

    const handleProfileView = () => {
        setMenuDisplay(false)
        window.open(`${getConfigDetails()?.portalURL}/${loggedInUserData()?.access_token}/${loggedInUserData()?.refresh_token}/2/${partner}`, '_self')
    }

    const handleLogOut = () => {
        setMenuDisplay(false)
        localStorage.removeItem('sign_in')
        localStorage?.setItem('isLoggedin', false)

    }

    return (
        <div className={`${className ? className : ''}  container-fluid py-3 border-bottom  border-primary`}>
            <div className='row g-0 align-items-center justify-content-between'>

                {isDisplayBack && <div className='col-auto'><a onClick={() => handleBackClick()} className='btn '> <i className='fa fa-angle-left me-2 text-primary' ></i></a></div>}
                {!noBrandIcon && <div className='col-3'><img
                    // style={{ height: '50px' }}
                    className='w-100 wmx-150'
                    src={partner ? partnerdetails ? partnerdetails?.brand_setting ? partnerdetails?.brand_setting?.logourl : partnerdetails?.partner_details?.brand_setting?.logourl : getPartnerDetails()?.brand_setting ? getPartnerDetails()?.brand_setting?.logourl : getPartnerDetails()?.partner_details?.brand_setting?.logourl : getPartnerLogo('parkengage')}
                    alt={partner ? partner : title}
                />
                </div>}
                <div className={'col text-center pt-1 text-primary'}>
                    <h6>{title}</h6>
                </div>

                {!noRightIcon && <div className='col-3 text-end  '>

                    {download && <a className='btn btn-sm'><i className='fa fa-download text-primary' onClick={() => handleDownload()}></i></a>}
                    {(isPermissionExist(getAccessPermissionData, 'Receipt Login Access')) &&
                        loggedInUserData()?.access_token ? <a className='btn btn-sm' onClick={() => handleMenu()} ><i className='far fa-user-circle text-primary' ></i> </a> : <a className='btn btn-sm' onClick={() => handleLogin()} ><i className="fa fa-sign-in text-primary"></i>
                    </a>}
                    {(menuDisplay) && <div className='menu-block d-block border rounded p-2  zIndex bg-white'>
                        {(isPermissionExist(getAccessPermissionData, 'Receipt Account Access')) && <span className='text-primary small border-bottom d-block pt-2 pb-2' onClick={() => handleMyAccountView()} >My Account</span>}
                        {(isPermissionExist(getAccessPermissionData, 'Receipt Profile Access')) && <span className='text-primary small border-bottom d-block pt-2 pb-2' onClick={() => handleProfileView()} >Profile</span>}
                        <span className='text-primary small d-block pt-2 pb-2' onClick={() => handleLogOut()} >Logout</span>
                    </div>}
                </div>}
            </div>
        </div>
    )
}

MiniHeader.propTypes = {
    isDisplayBack: PropTypes.bool,
    title: PropTypes.string,
    download: PropTypes.bool,
    handleDownload: PropTypes.func,
    className: PropTypes.string,
    partnerdetails: PropTypes?.object,
    facilityData: PropTypes.object,
    noRightIcon: PropTypes.bool,
    noBrandIcon: PropTypes.bool,

}

MiniHeader.defaulProps = {
    isDisplayBack: false,
    title: '',
    download: false,
    handleDownload: noop,
    className: '',
    partnerdetails: EMPTY_OBJECT,
    facilityData: EMPTY_OBJECT,
    noRightIcon: false,
    noBrandIcon: false,

}

function mapStateToProps({ transientReducer }) {
    return {
        loader: transientReducer?.loader,
        isFetching: transientReducer?.isFetching,
    }
}

export default connect(mapStateToProps)(MiniHeader)