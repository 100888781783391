import CONSTANTS from '../constants'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
    loader: false,
    isAuthenticated: false,
    hasError: false,
    data: EMPTY_OBJECT,
    faclityData: EMPTY_OBJECT,
    email: null,
    role: null,
    errorMessage: '',
    id: null,
    orgId: '',
    endTicketData: EMPTY_OBJECT
}

const promptReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // get facilty reducer 
        case CONSTANTS.PUT_MOBILE_NUMBER_REQUEST:
            return { ...state, loader: true, errorMessage: '' }
        case CONSTANTS.PUT_MOBILE_NUMBER_SUCCESS:
            return { ...state, loader: false, faclityData: action.data, isAuthenticated: true, hasError: false, errorMessage: '' }
        case CONSTANTS.PUT_MOBILE_NUMBER_FAILURE:
            return { ...state, loader: false, errorMessage: 'There is some Error' }
        default:
            return state
    }
}

export default promptReducer
