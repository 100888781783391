import React, { useEffect, useState } from 'react'
import { Card, Container, Form, Image, InputGroup } from 'react-bootstrap'
import userIcon from '../../../assets/img/user.png'
import emailImage from '../../../assets/img/email.png'
import keyImage from '../../../assets/img/key.png'
import usaMap from '../../../assets/img/map_usa.png'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
// import { errorMessage } from '../../../constants/errorMessage'
import { Circles } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'
import { MaskedInput, createDefaultMaskGenerator } from 'react-hook-mask'
import { CLIENT_SECRET, EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/globalConstants'
import { userSingIn, userSingUp } from '../../../redux/actions/singinAndSingUp'
import ConfirmationTerms from '../../../global/ConfirmationTerms'
import MiniHeader from '../MiniHeader'
import { getConfigDetails } from '../../../services/config'
import { errorMessage } from '../../../constants/errorMessage'
import { getPartnerDetailsBySLug } from '../../../redux/actions/userDetails'
import NoData from '../../NoData'
import { isPermissionExist, sortPortalPermissionByKey, restrictInValidCharPassword, validatePassword, getFirstErrorMessage } from '../../../utils/helper'
const phoneMaskGenerator = createDefaultMaskGenerator('(999) 999-9999')
require('./singup.scss')

function Singup(props) {
    const { dispatch, loader, notify, isFetching, partnerdetails } = props
    const getAccessPermissionData = sortPortalPermissionByKey(partnerdetails, 'Access Permissions')
    const partner = window.location.toString().split('/')[3]
    const { register, handleSubmit, control, formState: { errors }, reset, watch, setValue } = useForm()
    const navigate = useNavigate()

    const [passwordShown, setPasswordShown] = useState(false)
    const [cpasswordShown, setCPasswordShown] = useState(false)
    const [mpasswordShown, setMPasswordShown] = useState(false)
    const [termsModalOpen, setTermsModalOpen] = useState(false)
    const [error, setError] = useState({ password_strength: false, confirm_password_strength: false })
    const [phoneValModalOpen, setPhoneValModalOpen] = useState(false)
    const [formData, setFormData] = useState({})
    const [isValidPartner, setIsValidPartner] = useState(false)
    const [facilityData, setFacilityData] = useState(EMPTY_OBJECT)

    const togglePasswordVisiblity = (e, key) => {
        if (key === 'password') { setPasswordShown(passwordShown ? false : true) }
        if (key === 'mpassword') { setMPasswordShown(mpasswordShown ? false : true) }
        if (key === 'cpassword') { setCPasswordShown(cpasswordShown ? false : true) }
    }
    useEffect(() => {

        const facilityDetails = JSON.parse(localStorage.getItem('faclityData') ? localStorage.getItem('faclityData') : '{}')
        setFacilityData(facilityDetails)

        if (partner) {
            dispatch(getPartnerDetailsBySLug(partner)).then((res) => {
                if (res?.data?.email) {
                    // setPartnerDetails(res?.data)
                    setIsValidPartner(true)
                    localStorage.removeItem('partnerData')
                    localStorage.setItem('partnerData', JSON.stringify(res?.data))
                    const partnerData = res?.data
                    let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
                    let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
                    rgb = JSON.parse(rgb)

                    if (color) {
                        document.documentElement.style.setProperty('--bs-primary', color)
                        document.documentElement.style.setProperty('base-color', color)
                        document.documentElement.style.setProperty('primary', color)
                        document.documentElement.style.setProperty('textcolor', color)
                        document.documentElement.style.setProperty('--bs-btn-bg', color)
                        document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
                        document.documentElement.style.setProperty('--bs-list-group-active-border-color', color)
                        document.documentElement.style.setProperty('--bs-list-group-active-color', color)
                        document.documentElement.style.setProperty('--bs-list-group-active-bg', color)
                    }

                } else {
                    setIsValidPartner(false)
                    localStorage.removeItem('partnerData')
                }
            })
        }

    }, [EMPTY_ARRAY])

    const redirecttoLogin = () => {
        navigate(`/${partner}/login`)
    }

    const onSignUp = (data) => {
        if (!validatePassword(data?.password)) {
            setError({ ...error, password_strength: true })
            return
        } else if (!validatePassword(data?.confirm_password)) {
            setError({ ...error, confirm_password_strength: true })
            return
        }
        setFormData(data)
        const userPhoneNumber = localStorage.getItem('user_Phone_number')
        if (data?.termsAndCond) {
            if (!userPhoneNumber || userPhoneNumber == 'null' || userPhoneNumber == 'undefined' || userPhoneNumber == null || userPhoneNumber == undefined || userPhoneNumber === data?.phone) {
                singupApi(data)
            } else {
                setPhoneValModalOpen(!phoneValModalOpen)
            }
        } else {
            setTermsModalOpen(true)
        }
    }

    const singupApi = () => {
        const data = { ...formData }
        setPhoneValModalOpen(false)
        if (data?.confirm_password === data?.password) {
            const payload = {
                'first_name': data?.fullName,
                'last_name': '',
                'email': data?.email,
                'phone': data?.phone,
                'confirm_password': data?.confirm_password,
                'client_secret': CLIENT_SECRET,
                'facility_slug': facilityData?.facility?.short_name
            }
            dispatch(userSingUp(payload)).then((res) => {
                if (res?.status === 201) {
                    localStorage?.setItem('sign_up', JSON.stringify(res?.data))
                    const signInPayload = {
                        client_id: getConfigDetails()?.CLIENT_ID,
                        client_secret: getConfigDetails()?.CLIENT_SECRET,
                        grant_type: 'password',
                        username: data?.email,
                        password: data?.confirm_password
                    }
                    dispatch(userSingIn(signInPayload)).then((res) => {
                        if (res?.status === 201 && res?.data !== null) {
                            localStorage?.setItem('sign_in', JSON.stringify(res?.data))
                            localStorage?.setItem('isLoggedin', true)
                            notify('success', 'Registration Successfully Completed')
                            reset(formValues => ({
                                ...formValues,
                                'first_name': '',
                                'last_name': '',
                                'email': '',
                                'phone': '',
                                'confirm_password': '',
                                'client_secret': CLIENT_SECRET
                            }))
                            const loginHistory = localStorage.getItem('loginHistory')
                            navigate(loginHistory)
                        } else {
                            localStorage?.setItem('isLoggedin', false)

                            notify('success', res?.errors?.message ? res?.errors?.message : errorMessage?.registeration)
                            navigate(`/${partner}/login`)
                        }
                    }).catch((err) => {
                        localStorage?.setItem('isLoggedin', false)

                        notify('success', err?.errors?.message ? err?.errors?.message : errorMessage?.registeration)
                        navigate(`/${partner}/login`)
                    })
                } else {
                    notify('error', res?.errors?.message ? res?.errors?.message : errorMessage?.somethingWentWrong)
                }
            }).catch((err) => {
                notify('error', err?.errors?.message ? err?.errors?.message : errorMessage?.somethingWentWrong)
            })
        } else {
            notify('error', 'Password does not match')
        }
    }


    return (
        isFetching ? null : isValidPartner ? <div>
            <MiniHeader isDisplayBack title="Create Account" />
            <Container className='signupmain mt-2'>
                <p className='singtextInfo'>Unlock the best price and become an insider for our exclusive launches and offer</p>
            </Container>

            <Form onSubmit={handleSubmit(onSignUp)}>
                <Container>
                    <label className='form-label'>Your Information <span className="text-danger me-1">*</span></label>
                    <Card>
                        <InputGroup>
                            <InputGroup.Text id="inputLoginImage"><Image src={userIcon} width={23} height={23} /></InputGroup.Text>
                            <Form.Control type="text" name='fullName' aria-describedby="basic-addon1" className='form-control inputLogin' id='fullName' maxLength='40' minLength='1' placeholder="Full Name"  {...register('fullName', { required: { value: true, message: 'Full Name field is required' }, })} />
                        </InputGroup>
                        <InputGroup className='inputBorderUpper'>
                            <InputGroup.Text id="inputLoginImage"><Image src={emailImage} width={23} height={23} /></InputGroup.Text>
                            <Form.Control type="email" name='email' aria-describedby="basic-addon1" className='form-control inputLogin' id='email' maxLength='40' minLength='1' placeholder="Enter Email Address" {...register('email', {
                                required: { value: true, message: 'Email field is required' },
                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'invalid email address' },
                            })} />
                        </InputGroup>
                        <InputGroup className='inputBorderUpper'>
                            <InputGroup.Text className='mapFlagImage'><Image src={usaMap} width={23} height={23} /> +1</InputGroup.Text>
                            <Controller
                                name="phone"
                                type="text"
                                id="phone"
                                control={control}
                                defaultValue=''
                                rules={{
                                    required: { value: true, message: 'Phone field is Required' },
                                    minLength: { value: 10, message: 'Please enter a valid number' },
                                    maxLength: { value: 14, message: 'Please enter a valid number' }
                                }}
                                render={({ field }) => <MaskedInput
                                    maskGenerator={phoneMaskGenerator}
                                    className="form-control inputLogin"
                                    {...field}
                                    id="loginInputSingup"
                                    maxLength={14}
                                    inputMode="numeric"
                                    title="Please enter a valid phone number"
                                    onInvalid={e => e.target.setCustomValidity('Please enter phone number')}
                                    onInput={e => e.target.setCustomValidity('')}
                                    placeholder="(123)-555-1234"
                                />
                                }
                            />
                        </InputGroup>
                    </Card>
                    {getFirstErrorMessage(['fullName', 'email', 'phone'], errors) && (
                        <span style={{ color: 'red', fontSize: '13px' }}>
                            {getFirstErrorMessage(['fullName', 'email', 'phone'], errors)}
                        </span>
                    )}
                    <label htmlFor="exampleInputPassword1" className="form-label my-1 d-block">Set Your Password <span className="text-danger me-1">*</span>
                        <span className="popoverSection position-relative" >
                            <div className='small popoverContent position-absolute shadow-sm bg-white p-2 border'>Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-). </div>
                            <i className="fa fa-question-circle " />
                        </span>
                    </label>
                    <Card>
                        <InputGroup className='myiconset'>
                            <InputGroup.Text id="inputLoginImage"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                            <Form.Control
                                type={passwordShown ? 'text' : 'password'}
                                name='password'
                                className="form-control passwordInput inputLogin"
                                id="password"
                                placeholder="Enter Password"
                                aria-describedby="basic-addon1"
                                {...register('password', {
                                    required: { value: true, message: 'Password field is required' },
                                    pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-])[^()\\`~:;"/<>]{8,15}$/, message: 'Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' },
                                    onChange: (e) => setValue(e.target.name, restrictInValidCharPassword(e.target.value))
                                })} />
                            <i className={passwordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'password')}></i>
                        </InputGroup>
                        <InputGroup className='inputBorderUpper myiconset'>
                            <InputGroup.Text id="inputLoginImage"><Image width={23} height={23} src={keyImage} /></InputGroup.Text>
                            <Form.Control
                                type={cpasswordShown ? 'text' : 'password'}
                                name='confirm_password'
                                className="form-control passwordInput inputLogin"
                                id="confirm_password"
                                placeholder="Confirm Password"
                                aria-describedby="basic-addon1"
                                {...register('confirm_password', {
                                    required: { value: true, message: 'Confirm password field is required' },
                                    pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-])[^()\\`~:;"/<>]{8,15}$/, message: 'Confirm Password must be between 8 and 15 characters long and include at least one uppercase letter, one lowercase letter, one digit (0-9), and one special character (!, @, $, %, ^, &, *, #,?,-).' },
                                    validate: (val) => {
                                        if (watch('password') != val) {
                                            return 'Your passwords does not match'
                                        }
                                    },
                                    onChange: (e) => setValue(e.target.name, restrictInValidCharPassword(e.target.value))
                                })} />
                            <i className={cpasswordShown ? 'fas fa-eye-slash loginPassIcon' : 'fas fa-eye loginPassIcon'} onClick={(e) => togglePasswordVisiblity(e, 'cpassword')}></i>
                        </InputGroup>
                    </Card>
                    {getFirstErrorMessage(['password', 'confirm_password'], errors) && (
                        <span style={{ color: 'red', fontSize: '13px' }}>
                            {getFirstErrorMessage(['password', 'confirm_password'], errors)}
                        </span>
                    )}

                </Container>

                <Container className='mt-4 checkboxfortheSingup'>
                    <div className="">
                        <input className="mb-3 form-check-input" name='termsAndCond' type="checkbox" id="formHorizontalCheck"  {...register('termsAndCond')} />
                        <label className='checkboxSingup form-check-label' >{errorMessage?.termsNConditions}</label>
                    </div>
                </Container>

                <Container className="mt-2">
                    <button type="submit" name="submit" disabled={loader} className="btn w-100 my-4 text-white bg-primary">
                        <div className='d-flex justify-content-center align-items-center'>
                            <span>Create Account </span>  <span className='ms-2'>
                                {(loader) ? <Circles
                                    height="20"
                                    width="20"
                                    style={{ display: 'inline-block' }}
                                    color="#ffffff"
                                    ariaLabel="circles-loading"
                                    visible={true}
                                /> : null}
                            </span>
                        </div>
                    </button>
                </Container>

                {(isPermissionExist(getAccessPermissionData, 'Receipt Signup Access')) && <Container className='signupmain'>
                    <p className='AlreadyAccount'>Alredy have a account ? <span className='loginAccountAlredy' onClick={redirecttoLogin}>Login</span></p>
                </Container>}
            </Form>

            <ConfirmationTerms
                isConfimationModalOpen={termsModalOpen}
                setIsConfimationModalOpen={setTermsModalOpen}
                Message={errorMessage?.termsNConditions}
                cancleButtonColor='text-primary'
                cancelButton={true}
                cancelButtonLabel='OK'
            />

            <ConfirmationTerms
                isConfimationModalOpen={phoneValModalOpen}
                setIsConfimationModalOpen={setPhoneValModalOpen}
                Message={errorMessage?.signupMobileCheckMsgs}
                cancleButtonColor='text-danger'
                cancelButton={true}
                cancelButtonLabel='No'
                SubmitButton={true}
                SubmitButtonColor='text-primary'
                SubmitButtonLable='Yes'
                handleLogout={singupApi}
            />
        </div> : <NoData message={errorMessage?.invalidPartner} />
    )
}

Singup.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    notify: PropTypes.func,
    isFetching: PropTypes.bool,
    partnerdetails: PropTypes.object

}

Singup.defaulProps = {
    dispatch: noop,
    loader: false,
    notify: noop,
    isFetching: true,
    partnerdetails: EMPTY_OBJECT
}

function mapStateToProps({ singInAndSingUp, userDetailsReducer }) {
    return {
        loader: singInAndSingUp?.loader,
        isFetching: userDetailsReducer?.isFetching,
    }
}
export default connect(mapStateToProps)(Singup)