import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
// import pave from '../../assets/img/pave.png'
import SearchTicket from '../searchTicket/SearchTicket'
// import Footer from '../../components/global/Footer'
import MiniHeader from '../../components/global/MiniHeader'
import { useParams } from 'react-router-dom'
import NoData from '../../components/NoData'
import { errorMessage } from '../../constants/errorMessage'
import { getPartnerDetailsBySLug } from '../../redux/actions/userDetails'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import Loader from '../../global/Loader'
// import DownloadApp from '../../components/global/DownloadApp'

function Main(props) {

	const {partner, facility} = useParams()
	const {notify, dispatch, isFetching} = props
	const [isValidPartner, setIsValidPartner] = useState(false)
    const [partnerdetails, setPartnerDetails] = useState(EMPTY_OBJECT)
	const [loading, setLoading] = useState(true)


	useEffect(() => {
		if(partner){
			let slug = partner
			if(facility){
				slug = `${slug}/${facility}`
			}
			setLoading(true)
			dispatch(getPartnerDetailsBySLug(slug)).then((res) => {
				setLoading(false)
				if (res?.data?.email) {
					setPartnerDetails(res?.data)
					setIsValidPartner(true)
					localStorage.removeItem('partnerData')
					localStorage.setItem('partnerData',JSON.stringify(res?.data))
					const partnerData = res?.data
					let color = partnerData?.brand_setting ? partnerData?.brand_setting?.color : partnerData?.partner_details?.brand_setting?.color
                    let rgb = partnerData?.brand_setting ? partnerData?.brand_setting?.rgb_color : partnerData?.partner_details?.brand_setting?.rgb_color
                    rgb = JSON.parse(rgb)

					if(color){
						document.documentElement.style.setProperty('--bs-primary',color)
						document.documentElement.style.setProperty('base-color',color)
						document.documentElement.style.setProperty('primary',color)
						document.documentElement.style.setProperty('textcolor',color)
						document.documentElement.style.setProperty('--bs-btn-bg',color)
						document.documentElement.style.setProperty('--bs-primary-rgb', `${rgb?.r},${rgb?.g},${rgb?.b}`)
						document.documentElement.style.setProperty('--bs-list-group-active-border-color',color)
						document.documentElement.style.setProperty('--bs-list-group-active-color',color)
						document.documentElement.style.setProperty('--bs-list-group-active-bg',color)
					}
					
				} else {
					setIsValidPartner(false)
					localStorage.removeItem('partnerData')
					console.log('error')
				}
				
			}).catch(() => {
				setLoading(false)
				console.log('error')
				
			})
		}
		
	},[EMPTY_ARRAY])

	return (
		isFetching || loading ? <Loader/> : <div className='main'>
            <MiniHeader title='Search e-Receipt' partnerdetails={partnerdetails}/>
			{isValidPartner ? <SearchTicket notify={notify}/> : <NoData message={errorMessage?.invalidPartner}/>}
			{/* <DownloadApp/> */}
			{/* <Footer/> */}
		</div>
		
	)
}


Main.propTypes = {
	dispatch: PropTypes.func,
	loader: PropTypes.bool,
	loaderSms: PropTypes.bool,
	loaderDownload: PropTypes.bool,
	ticketDetailsFromProps: PropTypes.object,
	notify: PropTypes.func,
	isFetching: PropTypes.bool,
}

Main.defaulProps = {
	dispatch: noop,
	loader: true,
	ticketDetailsFromProps: {},
	notify: noop,
	isFetching: true,
}

function mapStateToProps({ userDetailsReducer }) {
	return {
		loader: userDetailsReducer?.loader,
		loaderSms: userDetailsReducer?.loaderSms,
		loaderDownload: userDetailsReducer?.loaderDownload,
		isFetching: userDetailsReducer?.isFetching,

	}
}
export default connect(mapStateToProps)(Main)

