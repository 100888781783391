import React, { useState } from 'react'
import QrReader from 'react-qr-scanner'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import MiniHeader from '../../components/global/MiniHeader'
import { EMPTY_OBJECT } from '../../constants/globalConstants'
import ConfirmationTerms from '../ConfirmationTerms'
import './qrScanCss.scss'
function CommonQr(props) {

    const { partnerDetails, onScan, onError, title, subTitle, isParentCss, isDisplayBack, isHeader, handleClose, noRightIcon, noBrandIcon } = props
    const [termsModalOpen, setTermsModalOpen] = useState(false)

    const constraints = window.constraints = {
        audio: false,
        video: { facingMode: 'environment' }
    }

    return (
        <div className={isParentCss ? '' : 'main'}>
            {isHeader && <MiniHeader isDisplayBack={isDisplayBack} noBrandIcon={noBrandIcon} noRightIcon={noRightIcon} title={title} partnerdetails={partnerDetails} />}
            {isDisplayBack && !isHeader && <div className='d-flex w-100'>
                <button className='btn btn-link ms-auto' onClick={handleClose}><i className='fa fa-close'></i></button>
            </div>}
            <div className={isHeader ? 'mt-5' : ''}>
                <p className='text-center  px-3 mt-3'>{subTitle}</p>
                <p className='mt-3 px-3 text-center small'>Please refresh or allow camera permission, if unable to Scan QR Code</p>
                <div className="parentScannerWrapper">
                    <QrReader
                        delay={100}
                        onError={onError}
                        onScan={onScan}
                        constraints={constraints}
                        className='scannerGuide'
                    />
                </div>
                <ConfirmationTerms
                    isConfimationModalOpen={termsModalOpen}
                    setIsConfimationModalOpen={setTermsModalOpen}
                    Message='Please provide the camera permission to scan the QR Code'
                    cancleButtonColor='text-primary'
                    cancelButton={true}
                    cancelButtonLabel='OK'
                />
            </div>
        </div>
    )

}

CommonQr.propTypes = {
    dispatch: PropTypes.func,
    loader: PropTypes.bool,
    isFetching: PropTypes.bool,
    isParentCss: PropTypes.bool,
    isDisplayBack: PropTypes.bool,
    noRightIcon: PropTypes.bool,
    noBrandIcon: PropTypes.bool,
    isHeader: PropTypes.bool,
    notify: PropTypes.func,
    onScan: PropTypes.func,
    onError: PropTypes.func,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    partnerDetails: PropTypes.object
}

CommonQr.defaulProps = {
    dispatch: noop,
    loader: false,
    isFetching: false,
    isParentCss: false,
    isDisplayBack: false,
    noRightIcon: false,
    noBrandIcon: false,
    isHeader: false,
    notify: noop,
    handleClose: noop,
    partnerDetails: EMPTY_OBJECT,
    onScan: noop,
    onError: noop,
    title: '',
    subTitle: ''
}

function mapStateToProps({ gatedCheckinReducer }) {
    return {
        loader: gatedCheckinReducer?.loader,
        isFetching: gatedCheckinReducer?.isFetching
    }
}
export default connect(mapStateToProps)(CommonQr)
