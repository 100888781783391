import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import ListingDataComp from './ListingDataComp'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants/globalConstants'
import AmountPaid from './AmountPaid'
import ExtendDetails from './ExtendDetails'
import { getExtendList } from '../../redux/actions/ExtendFromAction'
// import { useParams } from 'react-router-dom'
import Loader from '../../global/Loader'
import pdf_icon from '../../assets/img/pdf_icon.png'

function PaymentBreakUpDetails(props) {
    const { dispatch, className, sectionKey, paymentData, faciltyData, loader,  isDisplayDetails, isExtendDetails, breakDownTitle } = props

    const [extendedDetails, setExtendedDetails] = useState(EMPTY_OBJECT)
    const [isFetchingExtendDetails, setIsfetchingExtendDetails] = useState(false)
    const [isMore, setIsMore] = useState(false)
    // const {ticketId} = useParams()
    useEffect(() => {
        // console.log('hi')
        // setIsDisplayDetails(false)
       
        if (isExtendDetails) {
            setIsfetchingExtendDetails(true)
            dispatch(getExtendList(paymentData?.ticket_number)).then((res) => {
                setIsfetchingExtendDetails(false)
                setExtendedDetails(res?.data)
                res?.data?.lenght>0 ? setIsMore(true): setIsMore(false)
            }).catch((err) => {
                setIsfetchingExtendDetails(false)
                console.log(err)
            })
        }
    }, [EMPTY_ARRAY])

    // const handleCloseDetails = () => {
    //     setIsDisplayDetails(false)
    // }

    return (
        isDisplayDetails && <>
            <div className="card-header  d-flex justify-content-between">
                <p className="card-title text-primary">{breakDownTitle ? breakDownTitle : 'Price Breakdown'}</p>
                {/* <i className="fa fa-file"></i> */}
                <img src={pdf_icon} width="22px" height="21px" />
            </div>
            <div className="card-body">
                {isFetchingExtendDetails ? <Loader isMargin /> : <span>
                        <ListingDataComp loader={loader} paymentData={paymentData} />

                        {extendedDetails && extendedDetails?.length > 0 && (
                            <div className="d-block text-end">
                                <a className="text-primary" onClick={() => setIsMore(!isMore)}>
                                More{' '}
                                <i
                                    className={isMore ? 'fa fa-angle-up' : 'fa fa-angle-right'}
                                ></i>
                                </a>
                            </div>
                            )
                        }

                        {isExtendDetails && isMore && <ExtendDetails
                            className={className ? className : 'bg-white py-2'}
                            extendedDetails={extendedDetails}
                            paymentData={paymentData}
                        />}
                    </span>
                }
            </div>
            {isFetchingExtendDetails ? '' :
                <div className='card-footer'>
                    <AmountPaid
                        className={'row justify-content-between'}
                        faciltyData={faciltyData}
                        paymentData={paymentData}
                        sectionKey={sectionKey}
                        hideTaxIncluded={(paymentData?.tax_fee != '0.00' || paymentData?.tax_rate != '0.00' ) ? false : true}
                    />
                </div>
            }
            {/* <span className='text-danger small mb-3' onClick={() => handleCloseDetails()}>Close</span> */}
        </>
    )
}

PaymentBreakUpDetails.propTypes = {
    dispatch: PropTypes.func,
    className: PropTypes.string,
    faciltyData: PropTypes.object,
    sectionKey: PropTypes.string,
    paymentData: PropTypes.object,
    loader: PropTypes.bool,
    listData: PropTypes.object,
    isDisplayDetails: PropTypes.bool,
    isExtendDetails: PropTypes.bool,
    setIsDisplayDetails: PropTypes.func,
    breakDownTitle: PropTypes.string,
}

PaymentBreakUpDetails.defaulProps = {
    dispatch: noop,
    className: '',
    faciltyData: EMPTY_OBJECT,
    sectionKey: '',
    paymentData: EMPTY_OBJECT,
    loader: false,
    listData: EMPTY_OBJECT,
    isDisplayDetails: false,
    isExtendDetails: false,
    setIsDisplayDetails: noop,
    breakDownTitle: '',
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(PaymentBreakUpDetails)