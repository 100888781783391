import CONSTANTS from '../constants'
import { downloadFile, getRequest, postRequest } from '../../services'
import { APICONFIG } from '../apiConfig'
import { getConfigDetails } from '../../services/config'

export const getFaciltyData = () => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_FACILTY_REQUEST })
    return getRequest(APICONFIG.getPartnerDetails).then((res) => {
        dispatch({ type: CONSTANTS.GET_FACILTY_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_FACILTY_FAILURE })
        return err
    })
}

// // Calls the API to get user token
// export const userDetails = (creds) => (dispatch) => {
//     dispatch({ type: CONSTANTS.USER_DETAILS_REQUEST })
//     return postRequest(APICONFIG.getUserDetailUrl, creds).then((res) => {
//         dispatch({ type: CONSTANTS.USER_DETAILS_SUCCESS, data: res })
//         return res
//     }).catch((err) => {
//         dispatch({ type: CONSTANTS.USER_DETAILS_FAILURE })
//         return err
//     })
// }
// Calls the API to get user token
export const userDetails = (creds) => (dispatch) => {
    dispatch({ type: CONSTANTS.USER_DETAILS_REQUEST })
    return postRequest(APICONFIG.getSearchUrl, creds).then((res) => {
        dispatch({ type: CONSTANTS.USER_DETAILS_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.USER_DETAILS_FAILURE })
        return err
    })
}

export const sendSmsEmail = (creds) => (dispatch) => {
    dispatch({ type: CONSTANTS.SMS_EMAIL_REQUEST })
    return postRequest(APICONFIG.sendSmsEmailUrl, creds).then((res) => {
        dispatch({ type: CONSTANTS.SMS_EMAIL_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.SMS_EMAIL_FAILURE })
        return err
    })
}

export const downloadTicket = (payload) => (dispatch) => {
    dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_REQUEST })
    return downloadFile(`${APICONFIG.downloadEticketUrl}/${payload}/${getConfigDetails()?.PARTNER_ID}`, payload, `${payload ? payload : 'ticket'}.pdf`).then((res) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.DOWNLOAD_TICKET_FAILURE })
        return err
    })
}


// Calls the API to fetch Ticket Details
export const getTicketDetails = (payload) => (dispatch) => {
    const data = {...payload, 'device_type': 'web'}
    dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_REQUEST })
    return postRequest(APICONFIG.getSearchTicketUrl, data).then((res) => {
        dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_TICKET_DETAILS_FAILURE, errorMessage: err?.errors?.message })
        return err
    })
}

// Calls the API to fetch Ticket Details
export const getTicketList = (payload, page = '') => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_TICKET_LIST_REQUEST })
    let url = APICONFIG.getUserDetailUrl
    if(page){
        url = `${APICONFIG.getUserDetailUrl}?page=${page}`
    }

    return postRequest(url, payload).then((res) => {
        dispatch({ type: CONSTANTS.GET_TICKET_LIST_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_TICKET_LIST_FAILURE, errorMessage: err?.errors?.message })
        return err
    })
}

// GET PARTNER BY SLUG
export const getPartnerDetailsBySLug = (slug) => (dispatch) => {
    dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_REQUEST })
    return getRequest(`${APICONFIG.getPartnerBySlug}/${slug}`).then((res) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_SUCCESS, data: res })
        return res
    }).catch((err) => {
        dispatch({ type: CONSTANTS.GET_PARTNER_DETAILS_BY_SLUG_FAILURE, errorMessage: err })
        return err
    })
}