import CONSTANTS from '../constants'
import { EMPTY_OBJECT } from '../../constants/globalConstants'

const INITIAL_STATE = {
	loader: false,
	isAuthenticated: false,
	hasError: false,
	data: EMPTY_OBJECT,
	faclityData: EMPTY_OBJECT,
	email: null,
	role: null,
	errorMessage: '',
	id: null,
	orgId: '',
	endTicketData: EMPTY_OBJECT
}

const transientReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// get facilty reducer 
		case CONSTANTS.GET_FACILTY_REQUEST:
			return {
				...state,
				loader: true,
				errorMessage: ''
			}
		case CONSTANTS.GET_FACILTY_SUCCESS:
			return {
				...state,
				loader: false,
				faclityData: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.GET_FACILTY_FAILURE:
			return {
				...state,
				loader: false,
				errorMessage: 'There is some Error'
			}

		// for the payment api 
		case CONSTANTS.POST_CARD_PAYMENT_REQUEST:
			return {
				...state,
				isFetching: true,
				errorMessage: ''
			}
		case CONSTANTS.POST_CARD_PAYMENT_SUCCESS:
			return {
				...state,
				isFetching: false,
				faclityData: action.data,
				isAuthenticated: true,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.POST_CARD_PAYMENT_FAILURE:
			return {
				...state,
				isFetching: false,
				errorMessage: 'There is some Error'
			}

		// End Parking
		case CONSTANTS.END_PARKING_REQUEST:
			return {
				...state,
				isFetching: true,
				errorMessage: ''
			}
		case CONSTANTS.END_PARKING_SUCCESS:
			return {
				...state,
				isFetching: false,
				endTicketData: action.data,
				hasError: false,
				errorMessage: ''
			}
		case CONSTANTS.END_PARKING_FAILURE:
			return {
				...state,
				isFetching: false,
				hasError: true,
				errorMessage: 'There is some Error'
			}
		default:
			return state
	}
}

export default transientReducer
