import { combineReducers } from 'redux'
import userDetailsReducer from './userDetailsReducer'
import transientReducer from './transientReducer'
import promptReducer from './PromptReducer'

export default combineReducers({
	userDetailsReducer,
	transientReducer,
	promptReducer
})
