import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { connect } from 'react-redux'
import { EMPTY_OBJECT } from '../constants/globalConstants'

function ConfirmationTerms(props) {
    const { isConfimationModalOpen, setIsConfimationModalOpen, Message, cancelButton, SubmitButton, cancelButtonLabel, SubmitButtonLable, SubmitButtonColor, handleLogout, handleSubmit, isHandleSubmit, warning, data, cancleButtonColor } = props

    return (
        <>
            <Modal show={isConfimationModalOpen} onHide={() => setIsConfimationModalOpen()} centered>
                <Modal.Body className='messageCss'>{Message}</Modal.Body>
                <Modal.Footer className='confirationModalfooter py-0'>
                    {(SubmitButton) && <Button variant={`light ${SubmitButtonColor}`} className='logoutListMenu pt-0 pb-2' onClick={() => handleLogout()}>{SubmitButtonLable}</Button>}
                    {(cancelButton) && <Button variant={`light ${cancleButtonColor}`} className='logoutListMenu pt-0 pb-2' onClick={() => setIsConfimationModalOpen()}>{cancelButtonLabel}</Button>}
                    {isHandleSubmit && <Button variant={`light ${SubmitButtonColor}`} className='logoutListMenu pt-0 pb-2' onClick={() => handleSubmit(warning, data)}>{SubmitButtonLable}</Button>}
                </Modal.Footer>
            </Modal>
        </>
    )
}

ConfirmationTerms.propTypes = {
    dispatch: PropTypes.func,
    isConfimationModalOpen: PropTypes.bool,
    setIsConfimationModalOpen: PropTypes.func,
    Message: PropTypes.string,
    cancelButton: PropTypes.bool,
    cancelButtonLabel: PropTypes.string,
    SubmitButton: PropTypes.bool,
    SubmitButtonLable: PropTypes.string,
    SubmitButtonColor: PropTypes.string,
    handleLogout: PropTypes.func,
    handleSubmit: PropTypes.func,
    isHandleSubmit: PropTypes.bool,
    warning: PropTypes.string,
    data: PropTypes.object,
    cancleButtonColor: PropTypes.string
}

ConfirmationTerms.defaulProps = {
    dispatch: noop,
    isConfimationModalOpen: false,
    setIsConfimationModalOpen: noop,
    Message: '',
    cancelButton: false,
    cancelButtonLabel: '',
    SubmitButton: false,
    SubmitButtonLable: '',
    SubmitButtonColor: '',
    handleLogout: noop,
    handleSubmit: noop,
    isHandleSubmit: false,
    warning: '',
    data: EMPTY_OBJECT,
    cancleButtonColor: ''
}

function mapStateToProps() {
    return {}
}

export default connect(mapStateToProps)(ConfirmationTerms)